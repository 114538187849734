
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';
import AddTimeAuthorizations from './addTimeAuthorizations';


class TimeAuthorizationsPerCompany extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    // const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const firstday = function(y,m){
        return  new Date(y, m +1).getDate();
    }
    const lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
    }

    const today = Date.now();
    const month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today);
    const year = new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(today);

    const Firstday = firstday(year,month);
    const Lastday = lastday(year,month);
    
    const firstday_updated_month_day_year_today = month + '/' + Firstday + '/' + year;
    const lastday_updated_month_day_year_today = month + '/' + Lastday + '/' + year;

    const Firstdayformated = moment(firstday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");
    const Lastdayformated = moment(lastday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");

    var FirstdayUnixTimestamp = Math.round(new Date(Firstdayformated).getTime()/1000);
    var LastdayUnixTimestamp = Math.round(new Date(Lastdayformated).getTime()/1000);

    
    axios.get('http://payroll.nikkolim.com/get_time_authorizations/company_employee_time_authorizations/'+company_id+'/'+FirstdayUnixTimestamp+'/'+LastdayUnixTimestamp, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_ta_employee',
        text: 'Employee',
      }, {
        dataField: 'field_ta_label',
        text: 'Label'
      }, {
        dataField: 'field_ta_entry_date',
        text: 'Entry Date'
      }, {
        dataField: 'field_ta_company',
        text: 'Company'
      },{
        dataField: 'field_ta_date_from',
        text: 'From'
      },{
        dataField: 'field_ta_date_to',
        text: 'To'
      },{
        dataField: 'field_ta_shift_group',
        text: 'Shift Group'
      },{
        dataField: 'field_ta_department',
        text: 'Department'
      },{
        dataField: 'field_ta_type',
        text: 'Type'
      },{
        dataField: 'field_ta_approver',
        text: 'Approver'
      },{
        dataField: 'field_ta_invalid',
        text: 'Invalid'
      },{
        dataField: 'field_ta_active',
        text: 'Active'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
      <div>
        <h1 class="h3 mb-0 text-gray-800">Time Authorizations</h1>
        <div className="add-button"><AddTimeAuthorizations></AddTimeAuthorizations></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_ta_employee' >Employee</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_ta_label' filter={textfilter}>Label</TableHeaderColumn>
          <TableHeaderColumn dataField='field_ta_entry_date' filter={datefilter}>Entry Date</TableHeaderColumn>
          <TableHeaderColumn dataField='field_ta_company'>Company</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_date_from' filter={datefilter}>From</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_date_to' filter={datefilter}>To</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_shift_group' filter={textfilter}>Shift Group</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_department' filter={textfilter}>Department</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_type' filter={textfilter}>Type</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_approver' filter={textfilter}>Approver</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_invalid' filter={textfilter}>Invalid</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_ta_active' filter={textfilter}>Active</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default TimeAuthorizationsPerCompany;

