
import React from 'react';
import { getSessionID, getSessionName, getCompanyID } from '../../../Utils/Common';
import axios from 'axios';
import $ from 'jquery';
import { Tab,Row,Col,Nav,Tabs} from 'react-bootstrap';
import ListofEmployees from '../ListOfEmployees/ListOfEmployees';
import Departments from '../Departments/Departments';
import Sites from '../Sites/Sites';
import EmployeePositions from '../EmployeePositions/EmployeePositions';

import './CompanyInfo.css';
import Devices from '../Configurations/Devices/Devices';
import PayTypes from '../Configurations/PayTypes/PayTypes';

class CompanyInfo extends React.Component {
  constructor (props) {
      super();

      this.state = {
          company_name: [],
          company_image:'',
          company_phone:[],
          company_email:[],
          company_address:[],
          company_id:[]
      };
  }
  
  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    axios.get('http://payroll.nikkolim.com/get_companies/companies/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    },
    timeout: 180000,
  })
  .then((response) => {
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val.attributes));
        self.setState({ company_name: val.attributes.field_company_name });
        self.setState({ company_image: val.attributes.field_company_picture_src });
        self.setState({ company_phone: val.attributes.field_company_mobile });
        self.setState({ company_email: val.attributes.field_company_email });
        self.setState({ company_address: val.attributes.field_company_address });
        self.setState({ company_id: val.attributes.field_company_id });
        self.setState({ company_id: val.attributes.field_company_id });
        //console.log(JSON.stringify(val.attributes));
    });
    
    
  }, (error) => {
    console.log(error);
  });

  }
  
  render () {
      const { company_name,
              field_company_picture_src,
              company_phone,
              company_email,
              company_address,
              company_id
            } = this.state;

      return (
      <div>
            <div className="container-fluid">
                <div className="company-handler col col-md-12">
                    <div className="col col-md-2">
                        <div className="profile-img">
                        <img src={field_company_picture_src} alt="" />
                        </div>
                    </div>
                    <div className="col col-md-10">
                    <div className="profile-information">
                        <h2>{company_name}</h2>
                        <p><i className="fa fa-phone"></i> <span>{company_phone}</span></p>
                        <p><i className="fa fa-envelope"></i> <span>{company_email}</span></p>
                        <p><i className="fa fa-map-marker"></i> <span>{company_address}</span></p>
                    </div>
                    </div>
                </div>


        <Tabs className='col col-md-12 horizontal-tabs' defaultActiveKey="overview" id="uncontrolled-tab-example">
            <Tab eventKey="overview" title="Overview">
            <div className="row">
              <div className="col col-md-3 entity-field-label name">Name:</div>
              <div className="col col-md-7">{company_name}</div>
            </div>
            <div className="row">
              <div className="col col-md-3 entity-field-label name">Company ID:</div>
              <div className="col col-md-7">{company_id}</div>
            </div>
            <div className="row">
              <div className="col col-md-3 entity-field-label name">Phone No.:</div>
              <div className="col col-md-7">{company_phone}</div>
            </div>
            <div className="row">
              <div className="col col-md-3 entity-field-label name">E-mail Address:</div>
              <div className="col col-md-7">{company_email}</div>
            </div>
            <div className="row">
              <div className="col col-md-3 entity-field-label name">Address:</div>
              <div className="col col-md-7">{company_address}</div>
            </div>
            </Tab>
            <Tab eventKey="employees" title="Employees">
                <ListofEmployees></ListofEmployees>
            </Tab>
            <Tab eventKey="departments" title="Departments">
                <Departments></Departments>
            </Tab>
            <Tab eventKey="sites" title="Sites">
                <Sites></Sites>
            </Tab>
            <Tab eventKey="employee_positions" title="Employee positions">
                <EmployeePositions></EmployeePositions>
            </Tab>
            <Tab eventKey="devices" title="Devices">
                <Devices></Devices>
            </Tab>
            {/* <Tab eventKey="pay-types" title="Pay Types">
                <PayTypes></PayTypes>
            </Tab> */}
        </Tabs>
      </div>
  
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
      <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
            <div className="modal-footer">
              <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
              <a className="btn btn-primary" href="login.html">Logout</a>
            </div>
          </div>
        </div>
      </div>
     
    </div>  
      );
  }
};

export default CompanyInfo;

