import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../../Utils/Common';



class AddShiftOverrides extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      shift_definition:[],
      shift_override_employees:[],
      shift_override_no_excess_options:[],
      shift_override_excess_options:[],
      fields: {},
      errors: {},
      employee:{},
  };

    this.toggle = this.toggle.bind(this);
    this.getSelectValuesFromEmployee = this.getSelectValuesFromEmployee.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){    
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  getSelectValuesFromEmployee(event) {
    let employee = { employee: this.state.employee };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    employee = opts;
    this.setState({employee});
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Definition
    if(!fields["definition"]){
      formIsValid = false;
      errors["definition"] = "Cannot be empty";
    }

    //Definition
    if(!fields["hours"]){
      formIsValid = false;
      errors["hours"] = "Cannot be empty";
    }

    //No Excess Options
    if(!fields["noexcoptions"]){
      formIsValid = false;
      errors["noexcoptions"] = "Cannot be empty";
    }

    //Excess Options
    if(!fields["excoptions"]){
      formIsValid = false;
      errors["excoptions"] = "Cannot be empty";
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const shift_override = {
        field_shiftov_label: this.state.fields["name"],
        field_shiftov_company: company_id,
        field_shiftov_definition: this.state.fields["definition"],
        field_shiftov_employees:this.state.employee,
        field_shiftov_noexcoptions: this.state.fields["noexcoptions"],
        field_shiftov_excoptions: this.state.fields["excoptions"],
        field_shiftov_hours:this.state.fields["hours"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_shift_overrides/shift_overrides/`, shift_override, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/shift_overrides_company/shift_override_form', postData, axiosConfig)
    .then((response) => {
        self.setState({ company_id: company_id_login});
        self.setState({ company: response.data.field_shiftov_company});
        self.setState({ shift_definition: response.data.field_shiftov_definition});
        self.setState({ shift_override_employees: response.data.field_shiftov_employees});
        self.setState({ shift_override_no_excess_options: response.data.field_shiftov_noexcoptions});
        self.setState({ shift_override_excess_options: response.data.field_shiftov_excoptions});
        
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

        const { 
          company_id,
          company,
          shift_definition,
          shift_override_employees,
          shift_override_no_excess_options,
          shift_override_excess_options,
        } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Shift Override</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Shift Override</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset className='custom-fieldset' name="primaryInfo">
            <Label for="employee_information">Basic Information</Label>
              <Form.Row>
                  <Col md="7">
                      <Label>Label</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Shift Definition</Label>
                      <Form.Control as="select" value={this.state.fields["definition"]} required ref="definition" name="definition" onChange={this.handleChange.bind(this, "definition")}>
                        <option value='-'>-</option>
                        {shift_definition.map(sosd => (
                          <option value={sosd.id} key={sosd.id}>
                            {sosd.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["definition"]}</span>
                  </Col>
                  <Col>
                      <Label>Shift Hours</Label>
                      <Form.Control value={this.state.fields["hours"]} required ref="hours" step="0.01" type="number" name="hours" onChange={this.handleChange.bind(this, "hours")}  />
                      <span style={{color: "red"}}>{this.state.errors["hours"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>No Excess Options</Label>
                      <Form.Control as="select" value={this.state.fields["noexcoptions"]} required ref="noexcoptions" name="noexcoptions" onChange={this.handleChange.bind(this, "noexcoptions")}>
                        <option value='-'>-</option>
                          {shift_override_no_excess_options.map(soneo => (
                            <option value={soneo.id} key={soneo.id}>
                              {soneo.name}
                            </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["noexcoptions"]}</span>
                  </Col>
                  <Col>
                      <Label>Excess Options</Label>
                      <Form.Control as="select" value={this.state.fields["excoptions"]} required ref="excoptions" name="excoptions" onChange={this.handleChange.bind(this, "excoptions")}>
                          <option value='-'>-</option>
                          {shift_override_excess_options.map(soeo => (
                            <option value={soeo.id} key={soeo.id}>
                              {soeo.name}
                            </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["excoptions"]}</span>
                  </Col>
                  <Col>
                      <Label>Employees</Label>
                      <Form.Control as="select" multiple value={this.state.fields["employee"]} required ref="employee" name="employee" onChange={this.getSelectValuesFromEmployee}>
                        {shift_override_employees.map(soe => (
                          <option value={soe.id} key={soe.id}>
                            {soe.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
          </fieldset>
          </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddShiftOverrides;