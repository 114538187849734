import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getToken, getCompanyID } from '../../../../Utils/Common';

class AddPaySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      frequency:[],
      sss_collection:[],
      phic_collection:[],
      hdmf_collection:[],
      eom:'',
      whtaxcollect:'',
      paymonth:'',
      done:'',
      fields: {},
      errors: {}
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeEom = this.handleChangeEom.bind(this);
    this.handleChangeWhtaxcollect = this.handleChangeWhtaxcollect.bind(this);
    this.handleChangePaymonth = this.handleChangePaymonth.bind(this);
    this.handleChangeDone = this.handleChangeDone.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeEom(event) {
    if(event.target.checked == true){
      this.setState({eom: '1'});
    }
    else{
      this.setState({eom: '0'});
    }
  }

  handleChangeWhtaxcollect(event) {
    if(event.target.checked == true){
      this.setState({whtaxcollect: '1'});
    }
    else{
      this.setState({whtaxcollect: '0'});
    }
  }

  handleChangePaymonth(event) {
    if(event.target.checked == true){
      this.setState({paymonth: '1'});
    }
    else{
      this.setState({paymonth: '0'});
    }
  }

  handleChangeDone(event) {
    if(event.target.checked == true){
      this.setState({done: '1'});
    }
    else{
      this.setState({done: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Frequency
    if(!fields["frequency"]){
       formIsValid = false;
       errors["frequency"] = "Cannot be empty";
    }

    //Start Date
    if(!fields["start_date"]){
      formIsValid = false;
      errors["start_date"] = "Cannot be empty";
    }

    //End Date
    if(!fields["end_date"]){
      formIsValid = false;
      errors["end_date"] = "Cannot be empty";
    }

    //Pay Date
    if(!fields["pay_date"]){
      formIsValid = false;
      errors["pay_date"] = "Cannot be empty";
    }

    //Month Group
    if(!fields["month_group"]){
      formIsValid = false;
      errors["month_group"] = "Cannot be empty";
    }

    //Count
    if(!fields["count"]){
      formIsValid = false;
      errors["count"] = "Cannot be empty";
    }

    //SSS Collection
    if(!fields["sss_collection"]){
      formIsValid = false;
      errors["sss_collection"] = "Cannot be empty";
    }

    //SSS Multiplier
    if(!fields["sss_multiplier"]){
      formIsValid = false;
      errors["sss_multiplier"] = "Cannot be empty";
    }

    //SSS Divisor
    if(!fields["sss_divisor"]){
      formIsValid = false;
      errors["sss_divisor"] = "Cannot be empty";
    }

    //Phic Collection
    if(!fields["phic_collection"]){
      formIsValid = false;
      errors["phic_collection"] = "Cannot be empty";
    }

    //Phic Multiplier
    if(!fields["phic_multiplier"]){
      formIsValid = false;
      errors["phic_multiplier"] = "Cannot be empty";
    }

    //Phic Divisor
    if(!fields["phic_divisor"]){
      formIsValid = false;
      errors["phic_divisor"] = "Cannot be empty";
    }

    //hdmf Collection
    if(!fields["hdmf_collection"]){
      formIsValid = false;
      errors["hdmf_collection"] = "Cannot be empty";
    }

    //hdmf Multiplier
    if(!fields["hdmf_multiplier"]){
      formIsValid = false;
      errors["hdmf_multiplier"] = "Cannot be empty";
    }

    //hdmf Divisor
    if(!fields["hdmf_divisor"]){
      formIsValid = false;
      errors["hdmf_divisor"] = "Cannot be empty";
    }

    

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const pay_schedule = {
        field_paysched_company: company_id,
        field_paysched_frequency: this.state.fields["frequency"],
        field_paysched_start_date: this.state.fields["start_date"],
        field_paysched_end_date: this.state.fields["end_date"],
        field_paysched_pay_date: this.state.fields["pay_date"],
        field_paysched_month_group: this.state.fields["month_group"],
        field_paysched_is_eom: this.state.eom,
        field_paysched_count: this.state.fields["count"],
        field_paysched_sss_collection: this.state.fields["sss_collection"],
        field_paysched_sss_multiplier: this.state.fields["sss_multiplier"],
        field_paysched_sss_divisor: this.state.fields["sss_divisor"],
        field_paysched_phic_collection: this.state.fields["phic_collection"],
        field_paysched_phic_multiplier: this.state.fields["phic_multiplier"],
        field_paysched_phic_divisor: this.state.fields["phic_divisor"],
        field_paysched_hdmf_collection: this.state.fields["hdmf_collection"],
        field_paysched_hdmf_multiplier: this.state.fields["hdmf_multiplier"],
        field_paysched_hdmf_divisor: this.state.fields["hdmf_divisor"],
        field_paysched_wh_tax_collect: this.state.whtaxcollect,
        field_paysched_pay_month_13th: this.state.paymonth,
        field_paysched_done: this.state.done,
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_pay_schedules/pay_schedule/`, pay_schedule, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    var postData = {
      'company_id': company_id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/company_pay_schedules/pay_schedule_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
            self.setState({ company: response.data.field_paysched_company});
            self.setState({ frequency: response.data.field_paysched_frequency});
            self.setState({ sss_collection: response.data.field_paysched_sss_collection});
            self.setState({ phic_collection: response.data.field_paysched_phic_collection});
            self.setState({ hdmf_collection: response.data.field_paysched_hdmf_collection});
            //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

    const { 
      company,
      frequency,
      sss_collection,
      phic_collection,
      hdmf_collection,
  } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Pay Schedule</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Pay Schedule</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="pay_schedule_information">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Pay Frequency</Label>
                      <Form.Control as="select" value={this.state.fields["frequency"]} required ref="frequency" name="frequency" onChange={this.handleChange.bind(this, "frequency")}>
                        <option value='-'>-</option>
                        {frequency.map(frequency => (
                          <option value={frequency.id} key={frequency.id}>
                            {frequency.name}
                          </option>
                        ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                <Col md="3">
                    <Label>Start Date</Label>
                    <Form.Control value={this.state.fields["start_date"]} required ref="start_date" type="date" name="start_date" onChange={this.handleChange.bind(this, "start_date")}  />
                    <span style={{color: "red"}}>{this.state.errors["start_date"]}</span>
                </Col>
                <Col md="3">
                    <Label>End Date</Label>
                    <Form.Control value={this.state.fields["end_date"]} required ref="end_date" type="date" name="end_date" onChange={this.handleChange.bind(this, "end_date")}  />
                    <span style={{color: "red"}}>{this.state.errors["end_date"]}</span>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md="3">
                    <Label>Pay Date</Label>
                    <Form.Control value={this.state.fields["pay_date"]} required ref="pay_date" type="date" name="pay_date" onChange={this.handleChange.bind(this, "pay_date")}  />
                    <span style={{color: "red"}}>{this.state.errors["pay_date"]}</span>
                </Col>
                <Col md="3">
                    <Label>Month Group</Label>
                    <Form.Control value={this.state.fields["month_group"]} required ref="month_group" type="date" name="month_group" onChange={this.handleChange.bind(this, "month_group")}  />
                    <span style={{color: "red"}}>{this.state.errors["month_group"]}</span>
                </Col>
                <Col md="2">
                    <Label>Is EOM</Label>
                    <Form.Check 
                        type="switch"
                        id="switch-ps-eom"
                        label=""
                        onChange={this.handleChangeEom}
                        name="eom"
                    />
                </Col>
                <Col md="3">
                    <Label>Schedule Count</Label>
                    <Form.Control value={this.state.fields["count"]} required ref="count" type="number" min="1" max="64" name="count" onChange={this.handleChange.bind(this, "count")}  />
                    <span style={{color: "red"}}>{this.state.errors["count"]}</span>
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="pay_schedule_information">Collection Information</Label>
              <Form.Row>
                  <Col md="4">
                      <Label>SSS Collection Type</Label>
                      <Form.Control as="select" value={this.state.fields["sss_collection"]} required ref="sss_collection" name="sss_collection" onChange={this.handleChange.bind(this, "sss_collection")}>
                        {sss_collection.map(sss_collection => (
                          <option value={sss_collection.id} key={sss_collection.id}>
                            {sss_collection.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="2">
                      <Label>SSS Multiplier</Label>
                      <Form.Control value={this.state.fields["sss_multiplier"]} required ref="sss_multiplier" type="number" name="sss_multiplier" onChange={this.handleChange.bind(this, "sss_multiplier")} />
                      <span style={{color: "red"}}>{this.state.errors["sss_multiplier"]}</span>
                  </Col>
                  <Col md="2">
                      <Label>SSS Divisor</Label>
                      <Form.Control value={this.state.fields["sss_divisor"]} required ref="sss_divisor" type="number" name="sss_divisor" onChange={this.handleChange.bind(this, "sss_divisor")} />
                      <span style={{color: "red"}}>{this.state.errors["sss_divisor"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="4">
                      <Label>PHIC Collection Type</Label>
                      <Form.Control as="select" value={this.state.fields["phic_collection"]} required ref="phic_collection" name="phic_collection" onChange={this.handleChange.bind(this, "phic_collection")}>
                        {phic_collection.map(phic_collection => (
                          <option value={phic_collection.id} key={phic_collection.id}>
                            {phic_collection.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="2">
                      <Label>PHIC Multiplier</Label>
                      <Form.Control value={this.state.fields["phic_multiplier"]} required ref="phic_multiplier" type="number" name="phic_multiplier" onChange={this.handleChange.bind(this, "phic_multiplier")} />
                      <span style={{color: "red"}}>{this.state.errors["phic_multiplier"]}</span>
                  </Col>
                  <Col md="2">
                      <Label>PHIC Divisor</Label>
                      <Form.Control value={this.state.fields["phic_divisor"]} required ref="phic_divisor" type="number" name="phic_divisor" onChange={this.handleChange.bind(this, "phic_divisor")} />
                      <span style={{color: "red"}}>{this.state.errors["phic_divisor"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="4">
                      <Label>HDMF Collection Type</Label>
                      <Form.Control as="select" value={this.state.fields["hdmf_collection"]} required ref="hdmf_collection" name="hdmf_collection" onChange={this.handleChange.bind(this, "hdmf_collection")}>
                        {hdmf_collection.map(hdmf_collection => (
                          <option value={hdmf_collection.id} key={hdmf_collection.id}>
                            {hdmf_collection.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="2">
                      <Label>HDMF Multiplier</Label>
                      <Form.Control value={this.state.fields["hdmf_multiplier"]} required ref="hdmf_multiplier" type="number" name="hdmf_multiplier" onChange={this.handleChange.bind(this, "hdmf_multiplier")} />
                      <span style={{color: "red"}}>{this.state.errors["hdmf_multiplier"]}</span>
                  </Col>
                  <Col md="2">
                      <Label>HDMF Divisor</Label>
                      <Form.Control value={this.state.fields["hdmf_divisor"]} required ref="hdmf_divisor" type="number" name="hdmf_divisor" onChange={this.handleChange.bind(this, "hdmf_divisor")} />
                      <span style={{color: "red"}}>{this.state.errors["hdmf_divisor"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>WH-Tax Collect</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ps-whtaxcollect"
                        label=""
                        onChange={this.handleChangeWhtaxcollect}
                        name="whtaxcollect"
                      />
                  </Col>
                  <Col md="2">
                      <Label>Pay Month 13th</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ps-paymonth"
                        label=""
                        onChange={this.handleChangePaymonth}
                        name="paymonth"
                      />
                  </Col>
                  <Col md="2">
                      <Label>Done</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ps-done"
                        label=""
                        onChange={this.handleChangeDone}
                        name="done"
                      />
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
    );
  }
}

export default AddPaySchedule;