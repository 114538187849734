
import React from 'react';
import axios from 'axios';
import $ from 'jquery';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { getSessionID, getSessionName, getCompanyID } from '../../../../../Utils/Common';
import AddShiftOverrides from './addShiftOverrides';

class PayrollUserShiftOverrides extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }

    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    
    axios.get('http://payroll.nikkolim.com/get_shift_overrides/shift_overrides_company/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
    //   const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_shiftov_definition_label',
        text: 'Label',
      },{
        dataField: 'field_shiftov_company_label',
        text: 'Company'
      },{
        dataField: 'field_shiftov_employees',
        text: 'Employees'
      },{
        ataField: 'field_shiftov_excoptions',
        text: 'Excess Option'
      },{
        ataField: 'field_shiftov_noexcoptions',
        text: 'No Excess Options'
      },{
        ataField: 'field_shiftov_definition_label',
        text: 'Shift Definition'
      },{
        ataField: 'field_shiftov_hours',
        text: 'Shift Hours'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Shift Overrides</h1>
        <div className="add-button"><AddShiftOverrides></AddShiftOverrides></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_shiftov_definition_label' filter={textfilter}>Label</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_shiftov_company_label' filter={textfilter}>Company</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_shiftov_employees' filter={textfilter}>Employees</TableHeaderColumn>
          <TableHeaderColumn dataField='field_shiftov_excoptions'filter={textfilter} >Excess Option</TableHeaderColumn>
          <TableHeaderColumn dataField='field_shiftov_noexcoptions' filter={textfilter}>No Excess Options</TableHeaderColumn>
          <TableHeaderColumn dataField='field_shiftov_definition_label' filter={textfilter}>Shift Definition</TableHeaderColumn>
          <TableHeaderColumn dataField='field_shiftov_hours' filter={textfilter}>Shift Hours</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default PayrollUserShiftOverrides;

