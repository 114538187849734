
import React from 'react';
import axios from 'axios';
import $ from 'jquery';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import AdDdevice from './addDevices';


class Devices extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }   

    axios.get('http://payroll.nikkolim.com/get_devices/devices_company/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   

      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({ tableData: data });
      })
      //console.log(JSON.stringify(data));
    });
    // console.log(JSON.stringify(data));
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_biometrics_label',
        text: 'Device Name'
      },{
        dataField: 'field_biometrics_site_label',
        text: 'Sites',
      },{
        dataField: 'field_biometrics_company_label',
        text: 'Company',
      }];

      return (
      <div>
        <h1 className="h3 mb-0 text-gray-800">List of Device</h1>
        <div className="add-button"><AdDdevice></AdDdevice></div>
        <BootstrapTable responsive  wrapperClasses='table-responsive' bootstrap4 version='4' keyField='field_employee_id' columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_biometrics_label' filter={textfilter}>Device Name</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_biometrics_site_label' filter={textfilter}>Sites</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_biometrics_company_label' filter={textfilter}>Company</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default Devices;

