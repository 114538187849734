import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddAdjustments extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      employee:[],
      adjustment_type:[],
      adjustment_salary:[],
      adjustment_pay_record:[],
      fields: {},
      errors: {}
  };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Salary
    if(!fields["salary"]){
      formIsValid = false;
      errors["salary"] = "Cannot be empty";
   }

   //Entry Date
   if(!fields["entry_date"]){
    formIsValid = false;
    errors["entry_date"] = "Cannot be empty";
 }

  //Payment Date
  if(!fields["payment_date"]){
    formIsValid = false;
    errors["payment_date"] = "Cannot be empty";
  }

  //Amount
  if(!fields["amount"]){
      formIsValid = false;
      errors["amount"] = "Cannot be empty";
  }

   //Type
   if(!fields["type"]){
    formIsValid = false;
    errors["type"] = "Cannot be empty";
 }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }

      const id = this.props.employee_Id;
  
      const adjustments = {
        field_adjustment_name: this.state.fields["name"],
        field_adjustment_employee : id,
        field_adjustment_type: this.state.fields["type"],
        field_adjustment_salary : this.state.fields["salary"],
        field_adjustment_entry_date: this.state.fields["entry_date"],
        field_adjustment_payment_date: this.state.fields["payment_date"],
        field_adjustment_notes: this.state.fields["notes"],
        field_adjustment_amount: this.state.fields["amount"],
        field_adjustment_pay_record: this.state.fields["pay_record"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_adjustments/adjustments/`, adjustments, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }


  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_adjustments/adjustment_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
         self.setState({ employee: response.data.field_adjustment_employee});
         self.setState({ adjustment_type: response.data.field_adjustment_type});
         self.setState({ adjustment_salary: response.data.field_adjustment_salary});
         self.setState({ adjustment_pay_record: response.data.field_adjustment_pay_record});
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        employee,
        adjustment_type,
        adjustment_salary,
        adjustment_pay_record,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Adjustment</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Adjustment</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="adjustment">Basic Information</Label>
              <Form.Row>
                  <Col md="7">
                      <Label>Name</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Type</Label>
                      <Form.Control as="select" value={this.state.fields["type"]} required ref="type" name="type" onChange={this.handleChange.bind(this, "type")}>
                        {adjustment_type.map(adjt => (
                          <option value={adjt.id} key={adjt.id}>
                            {adjt.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["type"]}</span>
                  </Col>
                  <Col>
                      <Label>Salary</Label>
                      <Form.Control as="select" value={this.state.fields["salary"]} required ref="salary" name="salary" onChange={this.handleChange.bind(this, "salary")}>
                        {adjustment_salary.map(adjtss => (
                          <option value={adjtss.id} key={adjtss.id}>
                            {adjtss.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["salary"]}</span>
                  </Col>
                  <Col>
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                      <Label>Notes</Label>
                      <Form.Control as="textarea" value={this.state.fields["notes"]} required ref="notes" name="notes" onChange={this.handleChange.bind(this, "notes")}  />
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="payment-details">
            <Label for="adjustment">Payment Details</Label>
              <Form.Row>
                <Col>
                      <Label>Payment Date</Label>
                      <Form.Control value={this.state.fields["payment_date"]} required ref="payment_date" type="date" name="payment_date" onChange={this.handleChange.bind(this, "payment_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["payment_date"]}</span>
                </Col>
                <Col>
                      <Label>Amount</Label>
                      <Form.Control value={this.state.fields["amount"]} step="0.1" required ref="payment_date" type="number" name="amount" onChange={this.handleChange.bind(this, "amount")}  />
                      <span style={{color: "red"}}>{this.state.errors["amount"]}</span>
                </Col>
                <Col>
                      <Label>Pay Record</Label>
                      <Form.Control as="select" value={this.state.fields["pay_record"]} required ref="pay_record" name="pay_record" onChange={this.handleChange.bind(this, "pay_record")}>
                        <option value='-'>-</option>
                        {adjustment_pay_record.map(apr => (
                          <option value={apr.id} key={apr.id}>
                            {apr.name}
                          </option>
                        ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddAdjustments;