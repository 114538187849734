
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID, getUserID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';


class TimeRecords extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;
    
    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const user_id = getUserID();
    if (!user_id) {
      return;
    }


    const firstday = function(y,m){
        return  new Date(y, m +1).getDate();
    }
    const lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
    }

    const today = Date.now();
    const month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today);
    const year = new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(today);

    const Firstday = firstday(year,month);
    const Lastday = lastday(year,month);
    
    const firstday_updated_month_day_year_today = month + '/' + Firstday + '/' + year;
    const lastday_updated_month_day_year_today = month + '/' + Lastday + '/' + year;

    const Firstdayformated = moment(firstday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");
    const Lastdayformated = moment(lastday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");

    var FirstdayUnixTimestamp = Math.round(new Date(Firstdayformated).getTime()/1000);
    var LastdayUnixTimestamp = Math.round(new Date(Lastdayformated).getTime()/1000);


    axios.get('http://payroll.nikkolim.com/get_time_records/employee_time_records/'+user_id+'/'+FirstdayUnixTimestamp+'/'+LastdayUnixTimestamp, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      // const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_timerecord_date',
        text: 'Date',
      }, {
        dataField: 'field_timerecord_day',
        text: 'Day'
      }, {
        dataField: 'field_timerecord_expectedhours',
        text: 'Expected Hours'
      }, {
        dataField: 'field_timerecord_overtime',
        text: 'Overtime'
      },{
        dataField: 'field_timerecord_night',
        text: 'Night Deferential'
      },{
        dataField: 'field_timerecord_ndot',
        text: 'Night Deferential OT'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <div id="reportrange">
            <i className="fa fa-calendar"></i>&nbsp;
            <span></span> <i className="fa fa-caret-down"></i>
        </div>
        <BootstrapTable wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_timerecord_date'filter={datefilter} >Date</TableHeaderColumn>
          <TableHeaderColumn dataField='field_timerecord_day' filter={datefilter}>Day</TableHeaderColumn>
          <TableHeaderColumn dataField='field_timerecord_expectedhours' filter={datefilter}>Expected Hours</TableHeaderColumn>
          <TableHeaderColumn dataField='field_timerecord_overtime'>Overtime</TableHeaderColumn>
          <TableHeaderColumn dataField='field_timerecord_night'>Night Deferential</TableHeaderColumn>
          {/* <TableHeaderColumn dataField='field_timerecord_ndot'>Night Deferential OT</TableHeaderColumn> */}
          {/* <TableHeaderColumn dataField='entity_id' text='View Details' dataFormat={this.valueFormatter} >Operations</TableHeaderColumn> */}
        </BootstrapTable>
      </div>  
      );
  }
};


export default TimeRecords;

