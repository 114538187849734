import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddLeaverecordsPerEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      employee:[],
      leave_type:[],
      day_off_filter:[],
      holiday_filter:[],
      otp:'',
      active:'',
      fields: {},
      errors: {}
  };

    this.toggle = this.toggle.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangeotp = this.handleChangeotp.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleChangeotp(event) {
    if(event.target.checked == true){
      this.setState({otp: '1'});
    }
    else{
      this.setState({otp: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Leave Type
    if(!fields["leave_type"]){
      formIsValid = false;
      errors["leave_type"] = "Cannot be empty";
    }

    //Entry Date
    if(!fields["entry_date"]){
      formIsValid = false;
      errors["entry_date"] = "Cannot be empty";
    }

    //Payment Date
    if(!fields["payment_date"]){
      formIsValid = false;
      errors["payment_date"] = "Cannot be empty";
    }

    //Total Days
    if(!fields["total_days"]){
      formIsValid = false;
      errors["total_days"] = "Cannot be empty";
    }

    //Start Date
    if(!fields["start_date"]){
      formIsValid = false;
      errors["start_date"] = "Cannot be empty";
    }

    //End Date
    if(!fields["end_date"]){
      formIsValid = false;
      errors["end_date"] = "Cannot be empty";
    }

    //Dayoff Filter
    if(!fields["dayoff_filter"]){
      formIsValid = false;
      errors["dayoff_filter"] = "Cannot be empty";
    }

    //Holiday Filter
    if(!fields["holiday_filter"]){
      formIsValid = false;
      errors["holiday_filter"] = "Cannot be empty";
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }

      const id = this.props.employee_Id;
  
      const leave_records = {
        field_lam_name: this.state.fields["name"],
        field_lam_employee: id,
        field_lam_leave_type: this.state.fields["leave_type"],
        field_lam_entry_date: this.state.fields["entry_date"],
        field_lam_one_time_payment: this.state.otp,
        field_lam_payment_date: this.state.fields["payment_date"],
        field_lam_total_days: this.state.fields["total_days"],
        field_lam_start_date: this.state.fields["start_date"],
        field_lam_end_date: this.state.fields["end_date"],
        field_lam_dayoff_filter: this.state.fields["dayoff_filter"],
        field_lam_holiday_filter: this.state.fields["holiday_filter"],
        field_lam_active: this.state.active,
        field_lam_notes: this.state.fields["notes"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_employee_leaves/leaves/`, leave_records, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }


  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_leaves/leave_availment_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
         self.setState({ employee: response.data.field_lam_employee});
         self.setState({ leave_type: response.data.field_lam_leave_type});
         self.setState({ day_off_filter: response.data.field_lam_dayoff_filter});
         self.setState({ holiday_filter: response.data.field_lam_holiday_filter});
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
      leave_type,
      employee,
      day_off_filter,
      holiday_filter
  } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Leave Record</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Leave Record</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="leave_records_per_employee">Basic Information</Label>
              <Form.Row>
                  <Col md='7'>
                      <Label>Name</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="4">
                      <Label>Leave Type</Label>
                      <Form.Control as="select" value={this.state.fields["leave_type"]} required ref="leave_type" name="leave_type" onChange={this.handleChange.bind(this, "leave_type")}>
                          <option value='-'>-</option>
                        {leave_type.map(leave_type => (
                          <option value={leave_type.id} key={leave_type.id}>
                            {leave_type.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["leave_type"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>One Time Payment</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-otp"
                        label=""
                        onChange={this.handleChangeotp}
                        name="otp"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Payment Date</Label>
                      <Form.Control value={this.state.fields["payment_date"]} required ref="payment_date" type="date" name="payment_date" onChange={this.handleChange.bind(this, "payment_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["payment_date"]}</span>
                  </Col>
                  <Col md="2">
                      <Label>Total Days</Label>
                      <Form.Control value={this.state.fields["total_days"]} required ref="total_days" type="number" name="total_days" onChange={this.handleChange.bind(this, "total_days")}  />
                      <span style={{color: "red"}}>{this.state.errors["total_days"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Start Date</Label>
                      <Form.Control value={this.state.fields["start_date"]} required ref="start_date" type="date" name="start_date" onChange={this.handleChange.bind(this, "start_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["start_date"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>End Day</Label>
                      <Form.Control value={this.state.fields["end_date"]} required ref="end_date" type="date" name="end_date" onChange={this.handleChange.bind(this, "end_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["end_date"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Day Off Filter</Label>
                      <Form.Control as="select" value={this.state.fields["dayoff_filter"]} required ref="dayoff_filter" name="dayoff_filter" onChange={this.handleChange.bind(this, "dayoff_filter")}>
                          <option value='-'>-</option>
                        {day_off_filter.map(day_off_filter => (
                          <option value={day_off_filter.id} key={day_off_filter.id}>
                            {day_off_filter.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["dayoff_filter"]}</span>
                  </Col>
                  <Col>
                      <Label>Holiday Filter</Label>
                      <Form.Control as="select" value={this.state.fields["holiday_filter"]} required ref="holiday_filter" name="holiday_filter" onChange={this.handleChange.bind(this, "holiday_filter")}>
                          <option value='-'>-</option>
                        {holiday_filter.map(holiday_filter => (
                          <option value={holiday_filter.id} key={holiday_filter.id}>
                            {holiday_filter.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lrpe"
                        label=""
                        onChange={this.handleChangeActive}
                        name="active"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Notes</Label>
                      <Form.Control as="textarea" value={this.state.fields["notes"]} required ref="notes" name="notes" onChange={this.handleChange.bind(this, "notes")}  />
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddLeaverecordsPerEmployee;