import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddLeaveAvailable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      employee:[],
      leave_type:[],
      total:[],
      total:'',
      balance:[],
      balance:'',
      fields: {},
      errors: {}
  };

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Total
    if(!fields["total"]){
       formIsValid = false;
       errors["total"] = "Cannot be empty";
    }

    //Balance
    if(!fields["balance"]){
      formIsValid = false;
      errors["balance"] = "Cannot be empty";
   }


    // if(typeof fields["name"] !== "undefined"){
    //    if(!fields["name"].match(/^[a-zA-Z]+$/)){
    //       formIsValid = false;
    //       errors["name"] = "Only letters";
    //    }        
    // }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }

      const id = this.props.employee_Id;
  
      const leave_available = {
        // field_empclasstype_name: this.state.fields["name"],
        // field_empclasstype_company: company_id,
        // field_empclasstype_active: this.state.active
        field_leaveavail_employee: id,
        field_leaveavail_type: this.state.fields["leave_type"],
        field_leaveavail_total: this.state.fields["total"],
        field_leaveavail_balance : this.state.fields["balance"]
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_leave_available/leave_available/`, leave_available, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_leave_available/leave_available_form', postData, axiosConfig)
    .then((response) => {
         self.setState({ employee: response.data.field_leaveavail_employee});
         self.setState({ leave_type: response.data.field_leaveavail_type});
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
      leave_type,
      employee,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Leave Available</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Leave Available</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            {/* <Label for="employee_information">Personal Information</Label> */}
              <Form.Row>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control as="select" onChange={this.handleChange}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Leave Type</Label>
                      <Form.Control as="select" ref="leave_type" name="leave_type" value={this.state.fields["leave_type"]}  onChange={this.handleChange.bind(this, "leave_type")}>
                        {leave_type.map(leave_type => (
                          <option value={leave_type.id} key={leave_type.id}>
                            {leave_type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Total</Label>
                      {/* <Form.Control type="number" step="0.01" placeholder="0.00"  /> */}
                      <Form.Control value={this.state.fields["total"]} required  step="0.01" ref="total" type="number" name="total" placeholder="0.00" onChange={this.handleChange.bind(this, "total")}  />
                      <span style={{color: "red"}}>{this.state.errors["total"]}</span>
                  </Col>
                  <Col>
                      <Label>Balance</Label>
                      {/* <Form.Control type="number" step="0.01" placeholder="0.00"/> */}
                      <Form.Control value={this.state.fields["balance"]} required  step="0.01" ref="balance" type="number" name="balance" placeholder="0.00" onChange={this.handleChange.bind(this, "balance")}  />
                      <span style={{color: "red"}}>{this.state.errors["balance"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddLeaveAvailable;