import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import AddPaySchedule from './addPaySchedule';



class PaySchedule extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }
    
    axios.get('http://payroll.nikkolim.com/get_pay_schedules/company_pay_schedules/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
        $.each(val, function( i, val2 ){
            data.push(val2.attributes);
            self.setState({
                tableData: data
            });
            
          })
          //console.log(JSON.stringify(data));
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_paysched_company_label',
        text: 'Company',
      },{
        dataField: 'field_paysched_frequency_label',
        text: 'Frequency'
      },{
        dataField: 'field_paysched_start_date',
        text: 'Start Date'
      },{
        dataField: 'field_paysched_end_date',
        text: 'End Date'
      },{
        dataField: 'field_paysched_pay_date',
        text: 'Pay Date'
      },{
        dataField: 'field_paysched_month_group',
        text: 'Month Group'
      },{
        dataField: 'field_paysched_is_eom',
        text: 'EOM'
      },{
        dataField: 'field_paysched_count',
        text: 'Count'
      },{
        dataField: 'field_paysched_sss_collection',
        text: 'SSS Collection'
      },{
        dataField: 'field_paysched_sss_multiplier',
        text: 'SSS Multiplier'
      },{
        dataField: 'field_paysched_sss_divisor',
        text: 'SSS Divisor'
      },{
        dataField: 'field_paysched_phic_collection',
        text: 'PHIC Collection'
      },{
        dataField: 'field_paysched_phic_multiplier',
        text: 'PHIC Multiplier'
      },{
        dataField: 'field_paysched_phic_divisor',
        text: 'PHIC Divisor'
      },{
        dataField: 'field_paysched_hdmf_collection',
        text: 'HDMF Collection'
      },{
        dataField: 'field_paysched_hdmf_multiplier',
        text: 'HDMF Multiplier'
      },{
        dataField: 'field_paysched_hdmf_divisor',
        text: 'HDMF Divisor'
      },{
        dataField: 'field_paysched_wh_tax_collect',
        text: 'WH TAX'
      },{
        dataField: 'field_paysched_done',
        text: 'PAY SCHED'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Pay Schedule</h1>
        <div className="add-button"><AddPaySchedule></AddPaySchedule></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_paysched_company_label' filter={textfilter}>Company</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_frequency_label' filter={textfilter}>Frequency</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_start_date' filter={datefilter}>Start Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_end_date' filter={datefilter}>End Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_pay_date' filter={datefilter}>Pay Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_month_group' filter={textfilter}>Month Group</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_is_eom' filter={textfilter}>EOM</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_count' filter={textfilter}>Count</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_sss_collection' filter={textfilter}>SSS Collection</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_sss_multiplier' filter={textfilter}>SSS Multiplier</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_sss_divisor' filter={textfilter}>SSS Divisor</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_phic_collection' filter={textfilter}>PHIC Collection</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_phic_multiplier' filter={textfilter}>PHIC Multiplier</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_phic_divisor' filter={textfilter}>PHIC Divisor</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_hdmf_collection' filter={textfilter}>HDMF Collection</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_hdmf_multiplier' filter={textfilter}>HDMF Multiplier</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_hdmf_divisor' filter={textfilter}>HDMF Divisor</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_wh_tax_collect' filter={textfilter}>WH Tax</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_paysched_done' filter={textfilter}>Pay Schedule</TableHeaderColumn>

        </BootstrapTable>
      </div>  
      );
  }
};



export default PaySchedule;

