import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getToken, getCompanyID } from '../../../../Utils/Common';


class AddClassificationChoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      team :'' ,
      country: '',
      company:[],
      classification_type:[],
      employee_classification:[],
    };

    this.toggle = this.toggle.bind(this);
	this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeCountry(event) {
    this.setState({country: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;



    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    var postData = {
      'company_id': company_id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/class_choice_company/class_choice_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
               self.setState({ company: response.data.field_empclasschoice_company});
               self.setState({ classification_type: response.data.field_empclasschoice_type});
               self.setState({ employee_classification: response.data.field_employees});
            // self.setState({ phic_collection: response.data.field_paysched_phic_collection});
            // self.setState({ hdmf_collection: response.data.field_paysched_hdmf_collection});
              //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

    const { 
      company,
      classification_type,
      employee_classification,
  } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Classification Choice</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Classification Choice</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="pay_schedule_information">Basic Information</Label>
              <Form.Row>
                  <Col md="7">
                      <Label>Name</Label>
                      <Form.Control type="text" />
                  </Col>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Classification Type</Label>
                      <Form.Control as="select" onChange={this.searchSubmit}>
                        {classification_type.map(classification_type => (
                          <option value={classification_type.id} key={classification_type.id}>
                            {classification_type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Employee Classification</Label>
                      <Form.Control multiple as="select" onChange={this.searchSubmit}>
                        {employee_classification.map(employee_classification => (
                          <option value={employee_classification.id} key={employee_classification.id}>
                            {employee_classification.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>Default</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-cc-default"
                        label=""
                      />
                  </Col>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-cc-active"
                        label=""
                      />
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Save</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
    );
  }
}

export default AddClassificationChoice;