import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../../Utils/Common';


class AddShiftDefinition extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      team :'' ,
      country: '',
      company:[],
      first_entry_rec:[],
      grace_per_rec:[],
      warn_tardiness:[],
      break_start_adjustment:[],
      break_type:[],
      break_excess_free_break_option:[],
  };

    this.toggle = this.toggle.bind(this);
	  this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeGender(event) {
    this.setState({gender: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/shift_definitions_company/shift_definitions_form', postData, axiosConfig)
    .then((response) => {
         self.setState({ company_id: company_id_login});
         self.setState({ company: response.data.field_definition_company});
         self.setState({ first_entry_rec: response.data.field_definition_first_entry_rec});
         self.setState({ grace_per_rec: response.data.field_definition_grace_per_rec});
         self.setState({ warn_tardiness: response.data.field_definition_warn_tardiness});
         self.setState({ break_start_adjustment: response.data.field_definitionbreaks.field_defbrk_ustartadj});
         self.setState({ break_type: response.data.field_definitionbreaks.field_defbrk_type});
         self.setState({ break_excess_free_break_option: response.data.field_definitionbreaks.field_defbrk_ex_free_option});
         
         //console.log(JSON.stringify(response.data.field_definitionbreaks.field_defbrk_ustartadj));

         
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

        const { 
          site,
          company_id,
          company,
          first_entry_rec,
          grace_per_rec,
          warn_tardiness,
          break_start_adjustment,
          break_type,
          break_excess_free_break_option,
        } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Shift Definition</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Shift Definition</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="employee_information">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Name</Label>
                      <Form.Control type="text" />
                  </Col>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                      {/* <Form.Control  disabled defaultValue={company} required type="text" /> */}
                  </Col>
                  
              </Form.Row>
              <Form.Row>
                  <Col md="4">
                      <Label>Entry DAte</Label>
                      <Form.Control type="date" />
                  </Col>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-active"
                        label=""
                      />
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="employee_information">Time Definition</Label>
              <Form.Row>
                    <Col>
                        <Label>Cut Off</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>Start</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>Start  Minimum</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>End</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>End Minimum</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col>
                        <Label>Cut Off Previous Day</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-copd"
                          label=""
                        />
                    </Col>
              </Form.Row>
                    <Form.Row>
                    <Col>
                        <Label>Start Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>Start  Minimum</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>End Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>End Maximum</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Start Tolerance Used</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-stu"
                          label=""
                        />
                    </Col>
                    <Col md="3">
                        <Label>End Tolerance Used</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-etu"
                          label=""
                        />
                    </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="employee_information">Late Trucking</Label>
              <Form.Row>
                    <Col md="3">
                        <Label>Invalid</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-invalid"
                          label=""
                        />
                    </Col>
                    <Col md="3">
                        <Label>Monitoring Tardiness</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-mt"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col>
                        <Label>Overtime Begins</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>Minimum Overtime</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                      <Label>First Entry When Late Recording</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                          <option value='-'>-</option>
                        {first_entry_rec.map(fer => (
                          <option value={fer.id} key={fer.id}>
                            {fer.name}
                          </option>
                          ))}
                      </Form.Control>
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col>
                        <Label>Work Duration</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                        <Label>Maximum Late Duration</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col>
                      <Label>Grace Period Excess Recording</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                          <option value='-'>-</option>
                        {grace_per_rec.map(gpc => (
                          <option value={gpc.id} key={gpc.id}>
                            {gpc.name}
                          </option>
                          ))}
                      </Form.Control>
                    </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="employee_information">General Warnings</Label>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Absent</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wa"
                          label=""
                        />
                    </Col>
                    <Col md="3">
                        <Label>Warn Unscheduled Break</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wub"
                          label=""
                        />
                    </Col>
                    <Col md="3">
                        <Label>Warn Break Not Taken</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wbnt"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Early Break</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-web"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Early Break Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Late Return On Break</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wlrob"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Late Return On Break Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Early In</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wei"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Early In Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Late Out</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wlo"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Late Out Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Discarded Early In</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wdei"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Discarded Early In Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Discarded Late Out</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wdlo"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Discarded Late Out Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Discarded Attendance</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wda"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Discarded Attendance Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn Discarded Overtime</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-wdo"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Warn Discarded Overtime Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warm Tardiness</Label>
                        <Form.Control defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                          {warn_tardiness.map(wt => (
                            <option value={wt.id} key={wt.id}>
                              {wt.name}
                            </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md="4">
                        <Label>Warn Tardiness Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="employee_information">Breaks</Label>
              <Form.Row>
                    <Col md="5">
                        <Label>Name</Label>
                        <Form.Control type="text" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Start</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="4">
                        <Label>Start Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="3">
                        <Label>Start Tolerance Used</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-stu"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Start Minimum</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="4">
                        <Label>Start Maximum</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="3">
                        <Label>Start Adjustment</Label>
                        <Form.Control defaultValue={[0]} as="select" onChange={this.searchSubmit}>
                          {break_start_adjustment.map(sa => (
                            <option value={sa.id} key={sa.id}>
                              {sa.name}
                            </option>
                            ))}
                        </Form.Control>
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>End</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="4">
                        <Label>End Tolerance</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="3">
                        <Label>End Tolerance Used</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-etu"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>End Minimum</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="4">
                        <Label>End Maximum</Label>
                        <Form.Control type="time" />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="4">
                        <Label>Break Duration</Label>
                        <Form.Control type="time" />
                    </Col>
                    <Col md="4">
                        <Label>Break Type</Label>
                        <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                          <option value='-'>-</option>
                          {break_type.map(bt => (
                            <option value={bt.id} key={bt.id}>
                              {bt.name}
                            </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col md="4">
                        <Label>Excess Free Break Option</Label>
                        <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                          <option value='-'>-</option>
                          {break_excess_free_break_option.map(befbo => (
                            <option value={befbo.id} key={befbo.id}>
                              {befbo.name}
                            </option>
                            ))}
                        </Form.Control>
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Ignore If Near First In</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-iinfi"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Ignore If Near First In Tolerance</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-iinfit"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Ignore If Near Last Out</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-iinlo"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Ignore If Near Last Out Tolerance</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-iinlot"
                          label=""
                        />
                    </Col>
              </Form.Row>
              <Form.Row>
                    <Col md="3">
                        <Label>Warn If Not Taken</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-wint"
                          label=""
                        />
                    </Col>
                    <Col md="4">
                        <Label>Include In Warning</Label>
                        <Form.Check 
                          type="switch"
                          id="switch-break-iiw"
                          label=""
                        />
                    </Col>
              </Form.Row>

            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Save</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddShiftDefinition;