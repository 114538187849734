import React from 'react';
import axios from 'axios';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../Utils/Common';


class EmployeeForm extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    // let self = this;

    // const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    axios.post('http://payroll.nikkolim.com/get_form/employees_company/employee_form', {
    headers: {
      'Content-Type': 'application/json',
      'Accept' : 'application/json',
      'X-CSRF-Token': login_token,
      'Authentication': session_Name+"="+session_ID,
    },
    body: {
      'company_id': company_id
    }
    
  })

//     axios.request({
//     url: 'http://payroll.nikkolim.com/get_form/employees_company/employee_form',
//     method: 'post',
//     headers: { 
//         'Accept' : 'application/x-www-form-urlencoded',
//         'Authentication': session_Name+"="+session_ID
//     }
//   })
  .then((response) => {
    // const data = [];
    // $.each( response.data, function( i, val ) {   
    //     console.log(JSON.stringify(val));
    // //   $.each(val, function( i, val2 ){
    // //     data.push(val2.attributes);
    // //     self.setState({
    // //         tableData: data
    // //     });
    // //     //console.log(JSON.stringify(data));
    // //   })
    // });
    console.log(JSON.stringify(response));
  }, (error) => {
    if (error.response === 401) console.log(error.response.data.message);
    else console.log("Something went wrong. Please try again later.");

  });
  
  }

  render () {
    //   const { tableData } = this.state;
    //   const textfilter = { type: 'TextFilter', delay: 1000};
    //   const datefilter = { type: 'DateFilter', delay: 1000};

    //   let datalist = [{
    //     dataField: 'field_time_employee_label',
    //     text: 'Employee',
    //   }, {
    //     dataField: 'field_time_entry_type',
    //     text: 'Type'
    //   }, {
    //     dataField: 'field_time_date',
    //     text: 'Date'
    //   }, {
    //     dataField: 'field_time_log',
    //     text: 'Time Log'
    //   },{
    //     dataField: 'field_time_site_label',
    //     text: 'Site'
    //   },{
    //     dataField: 'entity_id',
    //     text: 'ID'
    //   }];
      
      return (
        
      <div>

      </div>  
      );
  }
};



export default EmployeeForm;

