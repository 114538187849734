import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import axios from 'axios';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import Menu from './containers/MainMenu/main-menu';
import ListofEmployees from './containers/UserRoles/PayrollAdmin/ListOfEmployees/ListOfEmployees';
import CompanyInfo from './containers/UserRoles/PayrollAdmin/CompanyInfo/CompanyInfo';
import PrivateRoute from './containers/Utils/PrivateRoute';
import PublicRoute from './containers/Utils/PublicRoute';
import { getToken, setUserSession } from './containers/Utils/Common';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import EmployeeDetails from './containers/UserRoles/PayrollAdmin/EmployeeDetails/EmployeeDetails';
//import TimeLogsPerEmployee from './containers/UserRoles/PayrollAdmin/EmployeeDetails/TimeLogsPerEmployee/TimeLogsPerEmployee';
import DailyTimeRecordsPerEmployee from './containers/UserRoles/PayrollAdmin/EmployeeDetails/DailyTimeRecordsPerEmployee/DailyTimeRecordsPerEmployee';
import TimeRecordsPerCompany from './containers/UserRoles/PayrollAdmin/TimeManagement/TimeRecordsPerCompany/TimeRecordsPerCompany';
import TimeLogsPerCompany from './containers/UserRoles/PayrollAdmin/TimeManagement/TimeLogsPerCompany/TimeLogsPerCompany';
import Adjustments from './containers/UserRoles/PayrollAdmin/InternalTransactions/Adjustments/Adjustments';
import Holidays from './containers/UserRoles/PayrollAdmin/Configurations/Holidays/Holidays';
import LoanTypes from './containers/UserRoles/PayrollAdmin/Configurations/LoanTypes/LoanTypes';
import EmployeeOverview from './containers/UserRoles/Employee/EmployeeOverview/EmployeeOverview';
import TimeLogs from './containers/UserRoles/Employee/EmployeeOverview/TimeLogs/TimeLogs';
import TimeRecords from './containers/UserRoles/Employee/EmployeeOverview/TimeRecords/TimeRecords';
import TimeAuthorizationsPerCompany from './containers/UserRoles/PayrollAdmin/TimeManagement/TimeAuthorizationsPerCompany/TimeAuthorizationsPerCompany';
// import Example from './containers/Topheader/modal-logout';
import EmployeeForm from './containers/EmployeeForm/EmployeeForm';
import LeavesPerCompany from './containers/UserRoles/PayrollAdmin/InternalTransactions/Leaves/Leaves';
import AdjustmentTypes from './containers/UserRoles/PayrollAdmin/Configurations/AdjustmentTypes/AdjustmentTypes';
import LeaveRecordsPerEmployee from './containers/UserRoles/PayrollAdmin/EmployeeDetails/LeaveRecordsPerEmployee/LeaveRecordsPerEmployee';
import TimeAuthorizationsPerEmployee from './containers/UserRoles/PayrollAdmin/EmployeeDetails/TimeAuthorizationsPerEmployee/TimeAuthorizationsPerEmployee';
import EmployeeLeaves from './containers/UserRoles/Employee/MyTransactions/Leaves/EmployeeLeaves';
import EmployeeUserLoans from './containers/UserRoles/Employee/MyTransactions/Loans/Loans';
import EmployeeUserAdjustments from './containers/UserRoles/Employee/MyTransactions/Adjustments/EmployeeAdjustments';
import EmployeeUserTimeAuthorizations from './containers/UserRoles/Employee/EmployeeOverview/TimeAuthorizations/EmployeeTimeAuthorizations';
import EmployeeUserLeaveAvailable from './containers/UserRoles/Employee/EmployeeOverview/LeaveAvailable/EmployeeLeaveAvailable';
import Devices from './containers/UserRoles/PayrollAdmin/Configurations/Devices/Devices';
import Loans from './containers/UserRoles/PayrollAdmin/InternalTransactions/Loans/Loans';
import PayrollAdminUserShiftMaintenance from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/ShiftMaintenance';
import PayrollUserShiftDefinitions from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/Shift Definitions/ShiftDefinitions';
import PayrollUserShiftGroups from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/ShiftGroups/ShiftGroups';
import PayrollUserShiftSets from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/ShiftSets/ShiftSets';
import PayrollUserShiftAssignments from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/ShiftAssignments/ShiftAssignments';
import PayrollUserShiftOverrides from './containers/UserRoles/PayrollAdmin/Configurations/ShiftMaintenance/ShiftOverrides/ShiftOverrides';
import PayTypes from './containers/UserRoles/PayrollAdmin/Configurations/PayTypes/PayTypes';
import SalaryRateKeys from './containers/UserRoles/PayrollAdmin/Configurations/SalaryRateKeys/SalaryRateKeys';
import LoanRecords from './containers/UserRoles/PayrollAdmin/EmployeeDetails/LoanRecords/LoanRecords';
import AdjustmentsEmployee from './containers/UserRoles/PayrollAdmin/EmployeeDetails/Adjustments/Adjustments';
import loader from './loader.gif';
import EmployeeTypes from './containers/UserRoles/PayrollAdmin/Classifications/EmployeeTypes/EmployeeTypes';
import Classification from './containers/UserRoles/PayrollAdmin/Classifications/Classifications/Classifications';
import ClassificationChoice from './containers/UserRoles/PayrollAdmin/Classifications/ClassificationChoice/ClassificationChoice';
import ClassificationType from './containers/UserRoles/PayrollAdmin/Classifications/ClassificationType/ClassificationType';
import SalaryInformation from './containers/UserRoles/PayrollAdmin/EmployeeDetails/SalaryInformation/SalaryInformation';
import LeaveTypes from './containers/UserRoles/PayrollAdmin/Configurations/LeaveTypes/LeaveTypes';
import LeaveAvailable from './containers/UserRoles/PayrollAdmin/EmployeeDetails/LeaveAvailable/LeaveAvailable';
import AddLeaveAvailable from './containers/UserRoles/PayrollAdmin/EmployeeDetails/LeaveAvailable/addLeaveAvailable';
import PaySchedule from './containers/UserRoles/PayrollAdmin/SalaryManagement/Pay Schedule/PaySchedule';
import PayFrequency from './containers/UserRoles/PayrollAdmin/Configurations/PayFrequency/PayFrequency';


function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
  
    axios.get(`http://payroll.nikkolim.com/verifyToken?token=${token}`).then(response => {
      setUserSession(response.data.token, response.data.user, response.data.sessionid, response.data.session_Name, response.data.sessionid, response.data.company_id);
      setAuthLoading(false);
    }).catch(error => {
      setAuthLoading(false);
    });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">
      <div class="loder-custom">
        <img src={loader} alt="Logo" />;
      </div>
    </div>
  }
 
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/employees" component={ListofEmployees} />
        <PrivateRoute path="/company-info" component={CompanyInfo} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/main-menu" component={Menu} />
        <PrivateRoute path="/employee" component={Menu} />


        {/* Payroll User ( Employee Details) */}
        <PrivateRoute path="/employees-details/:id" component={EmployeeDetails}/>
        {/* <PrivateRoute path="/timelogs/:id" component={TimeLogsPerEmployee}/> */}
        <PrivateRoute path="/daily-time-records/:id" component={DailyTimeRecordsPerEmployee}/>
        <PrivateRoute path="/employee-time-authorizations/:id" component={TimeAuthorizationsPerEmployee}/>
        <PrivateRoute path="/loan-records/:id" component={LoanRecords}/>
        <PrivateRoute path="/adjustment/:id" component={AdjustmentsEmployee}/>
        <PrivateRoute path="/employee-leaves-record/:id" component={LeaveRecordsPerEmployee}/>
        <PrivateRoute path="/salary-information/:id" component={SalaryInformation}/>
        {/* End Payroll User ( Employee Details) */}

        {/* Configurations Tabs */}
        <PrivateRoute path="/shift-maintenance" component={PayrollAdminUserShiftMaintenance}/>
        <PrivateRoute path="/shift-definitions" component={PayrollUserShiftDefinitions}/>
        <PrivateRoute path="/shift-groups" component={PayrollUserShiftGroups}/>
        <PrivateRoute path="/shift-sets" component={PayrollUserShiftSets}/>
        <PrivateRoute path="/shift-assignments" component={PayrollUserShiftAssignments}/>
        <PrivateRoute path="/shift-overrides" component={PayrollUserShiftOverrides}/>
        <PrivateRoute path="/holidays" component={Holidays}/>
        <PrivateRoute path="/devices" component={Devices}/>
        <PrivateRoute path="/loan-types" component={LoanTypes}/>
        <PrivateRoute path="/leave-types" component={LeaveTypes}/>
        <PrivateRoute path="/adjustment-types" component={AdjustmentTypes}/>
        <PrivateRoute path="/salary-rate-keys" component={SalaryRateKeys}/>
        <PrivateRoute path="/pay-types" component={PayTypes}/>
        <PrivateRoute path="/pay-frequency" component={PayFrequency}/>
        {/* End Configurations Tabs */}




        <PrivateRoute path="/time-records" component={TimeRecordsPerCompany}/>
        <PrivateRoute path="/time-logs" component={TimeLogsPerCompany}/>
        <PrivateRoute path="/time-authorizations" component={TimeAuthorizationsPerCompany}/>
        <PrivateRoute path="/adjustments" component={Adjustments}/>
        <PrivateRoute path="/overview" component={EmployeeOverview}/>
        <PrivateRoute path="/employee-time-logs" component={TimeLogs}/>
        <PrivateRoute path="/employee-time-records" component={TimeRecords}/>
        <PrivateRoute path="/employee-form" component={EmployeeForm}/>
        <PrivateRoute path="/leaves" component={LeavesPerCompany}/>
        <PrivateRoute path="/loans" component={Loans}/>
        

        
        {/* <PrivateRoute path="/example" component={Example}/> */}
        <PrivateRoute path="/employee-leaves" component={EmployeeLeaves}/>
        <PrivateRoute path="/time-authorizations" component={TimeAuthorizationsPerCompany}/>
        <PrivateRoute path="/employee-loans" component={EmployeeUserLoans}/>
        <PrivateRoute path="/employee-adjustments" component={EmployeeUserAdjustments}/>
        <PrivateRoute path="/employee-time-authorizations" component={EmployeeUserTimeAuthorizations}/>
        <PrivateRoute path="/employee-leave-available" component={EmployeeUserLeaveAvailable}/>
        <PrivateRoute path="/employee-leave-available/:id" component={AddLeaveAvailable}/>
        {/* <PrivateRoute path="/leave-available/:id" component={LeaveAvailable}/> */}


        {/* Classification Tab For Payroll User */}
        <PrivateRoute path="/employee-types" component={EmployeeTypes}/>
        {/* <PrivateRoute path="/classification" component={Classification}/> */}
        <PrivateRoute path="/classification-choice" component={ClassificationChoice}/>
        <PrivateRoute path="/classification-type" component={ClassificationType}/>
        {/* End Classification Tab For Payroll User */}


        {/* Salaray Management */}
        <PrivateRoute path="/pay-schedule" component={PaySchedule}/>
        
      </Switch>
    </BrowserRouter>
  );
}



export default App;
