
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import AddHoliday from './addHolidays';

class Holidays extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    
    axios.get('http://payroll.nikkolim.com/get_holidays/holiday_definitions/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_holiday_label',
        text: 'Holiday',
      },{
        dataField: 'field_holiday_type_label',
        text: 'Holiday Type'
      }, {
        dataField: 'field_holiday_date',
        text: 'Date'
      },{
        dataField: 'field_holiday_site',
        text: 'Site'
      },
      {
        dataField: 'field_holiday_active',
        text: 'Active'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Holidays</h1>
        <div className="add-button"><AddHoliday></AddHoliday></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_holiday_label' >Holiday</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_holiday_type_label' filter={textfilter}>Holiday Type</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_holiday_date' filter={datefilter}>Date</TableHeaderColumn>
          <TableHeaderColumn dataField='field_holiday_site'>Site</TableHeaderColumn>
          <TableHeaderColumn dataField='field_holiday_active'>Active</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default Holidays;

