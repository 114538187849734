import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../../Utils/Common';



class AddShiftSets extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      sets_sunday:[],
      sets_monday:[],
      sets_tuesday:[],
      sets_wednesday:[],
      sets_thursday:[],
      sets_friday:[],
      sets_saturday:[],
      active:'',
      default:'',
      fields: {},
      errors: {}
  };

  this.toggle = this.toggle.bind(this);
  this.handleChangeActive = this.handleChangeActive.bind(this);
  this.handleChangeDefault = this.handleChangeDefault.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleChangeDefault(event) {
    if(event.target.checked == true){
      this.setState({default: '1'});
    }
    else{
      this.setState({default: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Entry Date
    if(!fields["entry_date"]){
      formIsValid = false;
      errors["entry_date"] = "Cannot be empty";
   }

   //Monday
   if(!fields["monday"]){
    formIsValid = false;
    errors["monday"] = "Cannot be empty";
   }

   //Tuesday
   if(!fields["tuesday"]){
    formIsValid = false;
    errors["tuesday"] = "Cannot be empty";
   }

   //Wednesday
   if(!fields["wednesday"]){
    formIsValid = false;
    errors["wednesday"] = "Cannot be empty";
   }

   //Thursday
   if(!fields["thursday"]){
    formIsValid = false;
    errors["thursday"] = "Cannot be empty";
   }

   //Friday
   if(!fields["friday"]){
    formIsValid = false;
    errors["friday"] = "Cannot be empty";
   }

   //Saturday
   if(!fields["saturday"]){
    formIsValid = false;
    errors["saturday"] = "Cannot be empty";
   }


   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const loan_types = {
        field_set_label: this.state.fields["name"],
        field_set_company: company_id,
        field_set_active: this.state.active,
        field_set_default: this.state.default,
        field_set_entry_date:this.state.fields["entry_date"],
        field_set_sunday:this.state.fields["sunday"],
        field_set_monday:this.state.fields["monday"],
        field_set_tuesday:this.state.fields["tuesday"],
        field_set_wednesday:this.state.fields["wednesday"],
        field_set_thursday:this.state.fields["thursday"],
        field_set_friday:this.state.fields["friday"],
        field_set_saturday:this.state.fields["saturday"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_shift_sets/shift_sets/`, loan_types, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/shift_sets_company/shift_set_form', postData, axiosConfig)
    .then((response) => {
        self.setState({ company_id: company_id_login});
         self.setState({ company: response.data.field_set_company});
         self.setState({ sets_sunday: response.data.field_set_sunday});
         self.setState({ sets_monday: response.data.field_set_monday});
         self.setState({ sets_tuesday: response.data.field_set_tuesday});
         self.setState({ sets_wednesday: response.data.field_set_wednesday});
         self.setState({ sets_thursday: response.data.field_set_thursday});
         self.setState({ sets_friday: response.data.field_set_friday});
         self.setState({ sets_saturday: response.data.field_set_saturday});
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

        const { 
          company_id,
          company,
          sets_sunday,     
          sets_monday,
          sets_tuesday,
          sets_wednesday,
          sets_thursday,
          sets_friday,
          sets_saturday,
        } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Shift Set</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Shift Set</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset className='custom-fieldset' name="primaryInfo">
            <Label for="employee_information">Basic Information</Label>
              <Form.Row>
                  <Col md="5">
                      <Label>Label</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" placeholder="Name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col>
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                  </Col>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="1">
                      <Label>Default</Label>
                      <Form.Check 
                          type="switch"
                          id="switch-shift-group-default"
                          label=""
                          onChange={this.handleChangeDefault}
                          name="active"
                        />
                  </Col>
                  <Col md="1">
                      <Label>Active</Label>
                      <Form.Check 
                          type="switch"
                          id="switch-shift-group-active"
                          label=""
                          onChange={this.handleChangeActive}
                          name="active"
                        />
                  </Col>
              </Form.Row>
          </fieldset>
          <fieldset className='custom-fieldset' name="primaryInfo">
            <Label for="employee_information">Day Set</Label>
              <Form.Row>
                  <Col md='3'>
                      <Label>Sunday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["sunday"]} required ref="sunday" name="sunday" as="select" onChange={this.handleChange.bind(this, "sunday")}>
                          <option value='-'>-</option>
                           {sets_sunday.map(sunday => (
                          <option value={sunday.id} key={sunday.id}>
                            {sunday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["sunday"]}</span>
                  </Col>
                
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Monday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["monday"]} required ref="monday" name="monday" as="select" onChange={this.handleChange.bind(this, "monday")}>
                          <option value='-'>-</option>
                        {sets_monday.map(monday => (
                          <option value={monday.id} key={monday.id}>
                            {monday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["monday"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Tuesday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["tuesday"]} required ref="tuesday" name="tuesday" as="select" onChange={this.handleChange.bind(this, "tuesday")}>
                          <option value='-'>-</option>
                        {sets_tuesday.map(tuesday => (
                          <option value={tuesday.id} key={tuesday.id}>
                            {tuesday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["tuesday"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Wednesday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["wednesday"]} required ref="wednesday" name="wednesday" as="select" onChange={this.handleChange.bind(this, "wednesday")}>
                          <option value='-'>-</option>
                        {sets_wednesday.map(wednesday => (
                          <option value={wednesday.id} key={wednesday.id}>
                            {wednesday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["wednesday"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Thursday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["thursday"]} required ref="thursday" name="thursday" as="select" onChange={this.handleChange.bind(this, "thursday")}>
                          <option value='-'>-</option>
                        {sets_thursday.map(thursday => (
                          <option value={thursday.id} key={thursday.id}>
                            {thursday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["thursday"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Friday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["friday"]} required ref="friday" name="friday" as="select" onChange={this.handleChange.bind(this, "friday")}>
                          <option value='-'>-</option>
                        {sets_friday.map(friday => (
                          <option value={friday.id} key={friday.id}>
                            {friday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["friday"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md='3'>
                      <Label>Saturday</Label>
                  </Col>
                  <Col md='5'>
                      <Form.Control value={this.state.fields["saturday"]} required ref="saturday" name="saturday" as="select" onChange={this.handleChange.bind(this, "saturday")}>
                          <option value='-'>-</option>
                        {sets_saturday.map(saturday => (
                          <option value={saturday.id} key={saturday.id}>
                            {saturday.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["saturday"]}</span>
                  </Col>
              </Form.Row>
          </fieldset>
          </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddShiftSets;