
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';


class LeavesPerCompany extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    // const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const firstday = function(y,m){
        return  new Date(y, m +1).getDate();
    }
    const lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
    }

    const today = Date.now();
    const month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today);
    const year = new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(today);

    const Firstday = firstday(year,month);
    const Lastday = lastday(year,month);
    
    const firstday_updated_month_day_year_today = month + '/' + Firstday + '/' + year;
    const lastday_updated_month_day_year_today = month + '/' + Lastday + '/' + year;

    const Firstdayformated = moment(firstday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");
    const Lastdayformated = moment(lastday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");

    var FirstdayUnixTimestamp = Math.round(new Date(Firstdayformated).getTime()/1000);
    var LastdayUnixTimestamp = Math.round(new Date(Lastdayformated).getTime()/1000);

    
    axios.get('http://payroll.nikkolim.com/get_employee_leaves/company_employee_leaves/'+company_id+'/'+FirstdayUnixTimestamp+'/'+LastdayUnixTimestamp, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
    //   const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_lam_name',
        text: 'Name',
      }, {
        dataField: 'field_lam_employee_label',
        text: 'Employee'
      }, {
        dataField: 'field_lam_leave_type_label',
        text: 'Type of Leave'
      }, {
        dataField: 'field_lam_entry_date',
        text: 'Date of Leave'
      },{
        dataField: 'field_lam_payment_date',
        text: 'Payment Date'
      },{
        dataField: 'field_lam_total_days',
        text: 'Total Leave'
      },{
        dataField: 'field_lam_start_date',
        text: 'Start Date'
      },{
        dataField: 'field_lam_end_date',
        text: 'End Date'
      },{
        dataField: 'field_lam_dayoff_filter',
        text: 'Day Off'
      },{
        dataField: 'field_lam_holiday_filter',
        text: 'Holiday'
      },{
        dataField: 'field_lam_notes',
        text: 'Note'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Leaves</h1>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_lam_name' >Name</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_lam_employee_label' filter={textfilter}>Employee</TableHeaderColumn>
          <TableHeaderColumn dataField='field_lam_leave_type_label'>Type of Leave</TableHeaderColumn>
          <TableHeaderColumn dataField='field_lam_entry_date'>Date of Leave</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_payment_date' filter={textfilter}>Payment Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_total_days' filter={textfilter}>Total</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_start_date' filter={textfilter}>Start Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_end_date' filter={textfilter}>End Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_dayoff_filter' filter={textfilter}>Day Off</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_holiday_filter' filter={textfilter}>Holiday</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_lam_notes' filter={textfilter}>Notes</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default LeavesPerCompany;

