import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddLoanRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      employee:[],
      salary:[],
      loan_type:[],
      adjustment_pay_record:[],
      schedule_criteria:[],
      released:'',
      strict_schedule:'',
      hold:'',
      active:'',
      fields: {},
      errors: {}
  };

      this.toggle = this.toggle.bind(this);
      this.handleChangeReleased = this.handleChangeReleased.bind(this);
      this.handleChangeStrictSchedule = this.handleChangeStrictSchedule.bind(this);
      this.handleChangeHold = this.handleChangeHold.bind(this);
      this.handleChangeActive = this.handleChangeActive.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeReleased(event) {
    if(event.target.checked == true){
      this.setState({released: '1'});
    }
    else{
      this.setState({released: '0'});
    }
  }

  handleChangeStrictSchedule(event) {
    if(event.target.checked == true){
      this.setState({strict_schedule: '1'});
    }
    else{
      this.setState({strict_schedule: '0'});
    }
  }

  handleChangeHold(event) {
    if(event.target.checked == true){
      this.setState({hold: '1'});
    }
    else{
      this.setState({hold: '0'});
    }
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Salary
    if(!fields["salary"]){
      formIsValid = false;
      errors["salary"] = "Cannot be empty";
    }

    //Loan Type
    if(!fields["loan_type"]){
      formIsValid = false;
      errors["loan_type"] = "Cannot be empty";
    }

    //Entry Date
    if(!fields["entry_date"]){
      formIsValid = false;
      errors["entry_date"] = "Cannot be empty";
    }

    //Original Amount
    if(!fields["original_amount"]){
      formIsValid = false;
      errors["original_amount"] = "Cannot be empty";
    }

    //Amount
    if(!fields["amount"]){
      formIsValid = false;
      errors["amount"] = "Cannot be empty";
    }

    //Balance
    if(!fields["balance"]){
      formIsValid = false;
      errors["balance"] = "Cannot be empty";
    }

    //Deduction
    if(!fields["deduction"]){
      formIsValid = false;
      errors["deduction"] = "Cannot be empty";
    }

    //Monthly Target
    if(!fields["monthly_target"]){
      formIsValid = false;
      errors["monthly_target"] = "Cannot be empty";
    }

    //Start Date
    if(!fields["start_date"]){
      formIsValid = false;
      errors["start_date"] = "Cannot be empty";
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const id = this.props.employee_Id;

      const loans = {
        field_loan_title: this.state.fields["name"],
        field_loan_employee: id,
        field_loan_salary: this.state.fields["salary"],
        field_loan_type: this.state.fields["loan_type"],
        field_loan_entry_date: this.state.fields["entry_date"],
        field_loan_original_amount: this.state.fields["original_amount"],
        field_loan_amount: this.state.fields["amount"],
        field_loan_balance: this.state.fields["balance"],
        field_loan_deduction: this.state.fields["deduction"],
        field_loan_monthly_target: this.state.fields["monthly_target"],
        field_loan_start_date: this.state.fields["start_date"],
        field_loan_schedule_criteria: '1',
        field_loan_released:this.state.released,
        field_loan_strict_schedule: this.state.strict_schedule,
        field_group_hold: this.state.hold,
        field_group_active: this.state.active,
        field_loan_notes: this.state.fields["notes"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_loans/loans`, loans, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/loans_employee/loan_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
          self.setState({ employee: response.data.field_loan_employee});
          self.setState({ salary: response.data.field_loan_salary});
          self.setState({ loan_type: response.data.field_loan_type});
          self.setState({ schedule_criteria: response.data.field_loan_schedule_criteria});
         //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        employee,
        salary,
        loan_type,
        schedule_criteria,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Loan</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Loan</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="adjustment">Basic Information</Label>
              <Form.Row>
                  <Col md="7">
                      <Label>Title</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Salary</Label>
                      <Form.Control as="select" value={this.state.fields["salary"]} required ref="salary" name="salary" onChange={this.handleChange.bind(this, "salary")}>
                          <option value='-'>-</option>
                          {salary.map(salary => (
                            <option value={salary.id} key={salary.id}>
                              {salary.name}
                            </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["salary"]}</span>
                  </Col>
                  <Col>
                      <Label>Loan Type</Label>
                      <Form.Control as="select" value={this.state.fields["loan_type"]} required ref="loan_type" name="loan_type" onChange={this.handleChange.bind(this, "loan_type")}>
                          <option value='-'>-</option>
                          {loan_type.map(loan_type => (
                            <option value={loan_type.id} key={loan_type.id}>
                              {loan_type.name}
                            </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["loan_type"]}</span>
                  </Col>
                  <Col>
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="amout-details">
            <Label for="adjustment">Amout Details</Label>
              <Form.Row>
                <Col>
                      <Label>Original Amount</Label>
                      <Form.Control value={this.state.fields["original_amount"]} required ref="original_amount" step="0.1" type="number" name="original_amount" onChange={this.handleChange.bind(this, "original_amount")}  />
                      <span style={{color: "red"}}>{this.state.errors["original_amount"]}</span>
                </Col>
                <Col>
                      <Label>Amount</Label>
                      <Form.Control value={this.state.fields["amount"]} required ref="amount" step="0.1" type="number" name="amount" onChange={this.handleChange.bind(this, "amount")} />
                      <span style={{color: "red"}}>{this.state.errors["amount"]}</span>
                </Col>
                <Col>
                      <Label>Balance</Label>
                      <Form.Control value={this.state.fields["balance"]} required ref="balance" step="0.1" type="number" name="balance" onChange={this.handleChange.bind(this, "balance")} />
                      <span style={{color: "red"}}>{this.state.errors["balance"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="payment-details">
            <Label for="adjustment">Payment Details</Label>
              <Form.Row>
                <Col>
                      <Label>Deduction</Label>
                      <Form.Control value={this.state.fields["deduction"]} required ref="deduction" step="0.1" type="number" name="deduction" onChange={this.handleChange.bind(this, "deduction")} />
                      <span style={{color: "red"}}>{this.state.errors["deduction"]}</span>
                </Col>
                <Col>
                      <Label>Monthly Target</Label>
                      <Form.Control value={this.state.fields["monthly_target"]} required ref="monthly_target" step="0.1" type="number" name="monthly_target" onChange={this.handleChange.bind(this, "monthly_target")} />
                      <span style={{color: "red"}}>{this.state.errors["monthly_target"]}</span>
                </Col>
                <Col>
                      <Label>Start Date</Label>
                      <Form.Control value={this.state.fields["start_date"]} required ref="start_date" type="date" name="start_date" onChange={this.handleChange.bind(this, "start_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["start_date"]}</span>
                  </Col>
                </Form.Row>
            </fieldset>
            <fieldset name="payment-schedule">
            <Label for="adjustment">Payment Shedule</Label>
              <Form.Row>
                <Col>
                      <Label>Shedule Criteria</Label>
                      <Form.Control type="text" placeholder="hindi pa ito clear" />
                </Col>
                <Col md="2">
                      <Label>Released</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lr-released"
                        label=""
                        onChange={this.handleChangeReleased}
                        name="released"
                      />
                </Col>
                <Col md="2">
                      <Label>Strict Schedule</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lr-strict-schedule"
                        label=""
                        onChange={this.handleChangeStrictSchedule}
                        name="strict_schedule"
                      />
                </Col>
                <Col md="2">
                      <Label>Hold</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lr-hold"
                        label=""
                        onChange={this.handleChangeHold}
                        name="hold"
                      />
                </Col>
                <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lr-active"
                        label=""
                        onChange={this.handleChangeActive}
                        name="active"
                      />
                </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                        <Label>Notes</Label>
                        <Form.Control value={this.state.fields["notes"]} required ref="notes" as="textarea" name="notes" onChange={this.handleChange.bind(this, "notes")}  />
                  </Col>  
                </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddLoanRecords;