import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Table } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../Utils/Common';

class AdDdevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      employee:[],
      site:[],
      fields: {},
      errors: {},
      site_select:'',
      values:[],
      selectedOption:null
  };
    this.toggle = this.toggle.bind(this);
    // this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  createUI(){
    return this.state.values.map((el, i) => 
        <div key={i}>
         {/* <input type="text" value={el||''} onChange={this.handleChange2.bind(this, i)} />
         <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/> */}

          <Table bordered id='students'>
            <tbody>
              <tr>
                <td>
                  <tr><Label>Employee</Label></tr>
                  <Form.Control as="select" value={this.state.value} ref="employee" name="employee" onChange={this.handleChange.bind(this, "site")}>
                      <option value='-'>-</option>
                        {this.state.employee.map(employee => (
                        <option value={employee.id} key={employee.id}>
                          {employee.name}
                        </option>
                      ))}
                  </Form.Control>
                </td>
                <td>
                  <tr><Label>Registration Date</Label></tr>
                  <Form.Control type="date" />
                </td>
                <td>
                  <tr><Label>Employee Device ID</Label></tr>
                  <Form.Control type="text" />
                </td>
                <td>
                  <tr><Label>Operation</Label></tr>
                  {/* <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/> */}
                  <div className="btn btn-sm btn-danger" onClick={this.removeClick.bind(this, i)} >Remove</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>          
    )
 }

  handleChange2(i, event) {
    let values = [...this.state.values];
    values[i] = event.target.value;
    this.setState({ values });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }
   

  addClick(){
    this.setState(prevState => ({ values: [...prevState.values, '']}))
  }

  removeClick(i){
    let values = [...this.state.values];
    values.splice(i,1);
    this.setState({ values });
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }
    if(!fields["site"]){
      formIsValid = false;
      errors["site"] = "Cannot be empty";
   }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {   

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const devices = {
        field_biometrics_label: this.state.fields["name"],
        field_biometrics_company: company_id,
        field_biometrics_site : this.state.fields["site"],
        field_position_default: this.state.default,
        field_position_active: this.state.active
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_devices/devices/`, devices, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/devices_company/biometrics_form', postData, axiosConfig)
    .then((response) => {
         self.setState({ company_id: company_id_login});
         self.setState({ company: response.data.field_biometrics_company});
         self.setState({ employee: response.data.employee_mapping.field_edm_employee});
         self.setState({ site: response.data.field_biometrics_site});
         //console.log(JSON.stringify(response.data));
        
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };




  render() {

    const { 
      company,
      company_id,
      site,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Device</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Device</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="employee_information">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Biometrics Label</Label>
                      <Form.Control value={this.state.fields["name"]} ref="name" type="text" name="name" placeholder="Name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                      {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="4">
                      <Label>Site</Label>
                      <Form.Control as="select" value={this.state.value} ref="site" name="site" onChange={this.handleChange.bind(this, "site")}>
                      <option value='-'>-</option>
                        {site.map(site => (
                          <option value={site.id} key={site.id}>
                            {site.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["site"]}</span>
                  </Col>
                  <Col md="4">
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset>
              <Label for="employee_device_mapping">Employee Device Mapping</Label>
              <Form.Row>
              {this.createUI()}
              {/* <input type='button' value='Add Employee Device' onClick={this.addClick.bind(this)}/> */}
              <div className="btn btn-sm btn-success" onClick={this.addClick.bind(this)} >Add Employee Device</div>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	      <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AdDdevice;