import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { removeUserSession } from '../Utils/Common';

// var selectedTab;

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modal: false,name: '',team :'' ,country: ''};

    this.toggle = this.toggle.bind(this);
	  this.handleLogout = this.handleLogout.bind(this);
	  this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
	  this.handleSubmit = this.handleSubmit.bind(this);
	  // selectedTab = 0;
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleLogout(props){
	// selectedTab = value;
	removeUserSession();
  // props.history.push('/login');
  window.location.href = '/login';
	//this.props.history.push('/login');
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeCountry(event) {
    this.setState({country: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }


  render() {
    return (

        <div>
        <Button color="primary" onClick={this.toggle} className="btn btn-primary" >
        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
          </Button>
        <Modal isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Ready to Leave?</ModalHeader>
          <ModalBody>
			Are you sure want to log out?
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
			<input type="button" onClick={this.handleLogout} color="primary" className="btn btn-primary" value="Logout" /> 
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default LogoutButton;