import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getToken, getCompanyID } from '../../../../Utils/Common';


class AddTimeAuthorizations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      employees:[],
      employees_type:[],
      sites:[],
      shift_group:[],
      departments:[],
      cutoff_application:[],
      earlyin_application:[],
      late_out_application:[],
      pay_schedule_period:[],
      fields: {},
      errors: {},
      employee:{},
      employee_type:{},
      site:{},
      shift_groups:{},
      department:{},
      ucutoff_absolute_tisdb:'',
      ulateout_coa_tisda:'',
      cutoff_iwho:'',
      earlyin_iwho:'',
      lateout_coa_iwho:'',
      adj_exp_arrival:'',
      invalid:'',
    };

    this.toggle = this.toggle.bind(this);
    this.getSelectValuesFromEmployee = this.getSelectValuesFromEmployee.bind(this);
    this.getSelectValuesFromEmployeeType = this.getSelectValuesFromEmployeeType.bind(this);
    this.getSelectValuesFromSites = this.getSelectValuesFromSites.bind(this);
    this.getSelectValuesFromShiftGroup = this.getSelectValuesFromShiftGroup.bind(this);
    this.getSelectValuesFromDepartments = this.getSelectValuesFromDepartments.bind(this);
    this.handleChangeucutoffabsolutetisdb = this.handleChangeucutoffabsolutetisdb.bind(this);
    this.handleChangeulateoutcoatisda = this.handleChangeulateoutcoatisda.bind(this);
    this.handleChangeuearlyincoatidb = this.handleChangeuearlyincoatidb.bind(this);
    this.handleChangecutoffiwho = this.handleChangecutoffiwho.bind(this);
    this.handleChangeearlyiniwho = this.handleChangeearlyiniwho.bind(this);
    this.handleChangelateoutcoaiwho = this.handleChangelateoutcoaiwho.bind(this);
    this.handleChangeadjexparrival = this.handleChangeadjexparrival.bind(this);
    this.handleChangeinvalid = this.handleChangeinvalid.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }


  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){    
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleChangeucutoffabsolutetisdb(event) {
    if(event.target.checked == true){
      this.setState({ucutoff_absolute_tisdb: '1'});
    }
    else{
      this.setState({ucutoff_absolute_tisdb: '0'});
    }
  }

  handleChangeulateoutcoatisda(event) {
    if(event.target.checked == true){
      this.setState({ulateout_coa_tisda: '1'});
    }
    else{
      this.setState({ulateout_coa_tisda: '0'});
    }
  }

  handleChangeuearlyincoatidb(event) {
    if(event.target.checked == true){
      this.setState({uearlyin_coa_tidb: '1'});
    }
    else{
      this.setState({uearlyin_coa_tidb: '0'});
    }
  }

  handleChangecutoffiwho(event) {
    if(event.target.checked == true){
      this.setState({cutoff_iwho: '1'});
    }
    else{
      this.setState({cutoff_iwho: '0'});
    }
  }

  handleChangeearlyiniwho(event) {
    if(event.target.checked == true){
      this.setState({earlyin_iwho: '1'});
    }
    else{
      this.setState({earlyin_iwho: '0'});
    }
  }

  handleChangelateoutcoaiwho(event) {
    if(event.target.checked == true){
      this.setState({lateout_coa_iwho: '1'});
    }
    else{
      this.setState({lateout_coa_iwho: '0'});
    }
  }

  handleChangeadjexparrival(event) {
    if(event.target.checked == true){
      this.setState({adj_exp_arrival: '1'});
    }
    else{
      this.setState({adj_exp_arrival: '0'});
    }
  }

  handleChangeinvalid(event) {
    if(event.target.checked == true){
      this.setState({invalid: '1'});
    }
    else{
      this.setState({invalid: '0'});
    }
  }

  getSelectValuesFromEmployee(event) {
    let employee = { employee: this.state.employee };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    employee = opts;
    this.setState({employee});
  }

  getSelectValuesFromEmployeeType(event) {
    let employee_type = { employee_type: this.state.employee_type };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    employee_type = opts;
    this.setState({employee_type});
  }

  getSelectValuesFromSites(event) {
    let site = { sites: this.state.site };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    site = opts;
    this.setState({site});
  }

  getSelectValuesFromShiftGroup(event) {
    let shift_groups = { shift_groups: this.state.shift_groups };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    shift_groups = opts;
    this.setState({shift_groups});
  }

  getSelectValuesFromDepartments(event) {
    let department = { department: this.state.department };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    department = opts;
    this.setState({department});
  }

  
  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const time_authorization = {
        field_ta_label: this.state.fields["name"],
        field_ta_entry_date: this.state.fields["entry_date"],
        field_ta_company: company_id,
        field_ta_date_from: this.state.fields["from"],
        field_ta_date_to: this.state.fields["to"],
        field_ta_employee: this.state.employee,
        field_ta_site: this.state.site,
        field_ta_shift_group: this.state.shift_groups,
        field_ta_department: this.state.department,
        field_ta_type: this.state.employee_type,
        field_ta_ucutoff_absolute_time: this.state.fields["ucutoff_absolute_time"],
        field_ta_ucutoff_absolute_tisdb:  this.state.ucutoff_absolute_tisdb,
        field_ta_uearlyin_coa_time: this.state.fields["uearlyin_coa_time"],
        field_ta_uearlyin_coa_tidb: this.state.uearlyin_coa_tidb,
        field_ta_ulateout_coa_time: this.state.fields["ulateout_coa_time"],
        field_ta_ulateout_coa_tisda: this.state.ulateout_coa_tisda,
        field_ta_cutoff_relative_adj: this.state.fields["cutoff_relative_adj"],
        field_ta_cutoff_application: this.state.fields["cutoff_application"],
        field_ta_cutoff_iwho: this.state.cutoff_iwho,
        field_ta_earlyin_rel_adj: this.state.fields["earlyin_rel_adj"],
        field_ta_earlyin_application: this.state.fields["earlyin_application"],
        field_ta_earlyin_iwho: this.state.earlyin_iwho,
        field_ta_lateout_rel_adj: this.state.fields["lateout_rel_adj"],
        field_ta_lateout_application: this.state.fields["lateout_application"],
        field_ta_lateout_coa_iwho:this.state.lateout_coa_iwho,
        field_ta_overtime_maximum_gain: this.state.fields["overtime_maximum_gain"],
        field_ta_overtime_maximum_dur: this.state.fields["overtime_maximum_dur"],
        field_ta_adj_exp_arrival: this.state.fields["adj_exp_arrival"],
        field_ta_pay_schedule_period: this.state.fields["pay_schedule_period"],
        field_ta_invalid: this.state.invalid,
        field_ta_active: this.state.active,
        field_ta_approver: this.state.fields["approver"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_time_authorizations/time_authorizations/`, time_authorization, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    var postData = {
      'company_id': company_id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/company_employee_time_authorizations/time_authorization_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
            self.setState({ company: response.data.field_ta_company});
            self.setState({ employees: response.data.field_ta_employee});
            self.setState({ employees_type: response.data.field_ta_type});
            self.setState({ sites: response.data.field_ta_site});
            self.setState({ shift_group: response.data.field_ta_shift_group});
            self.setState({ departments: response.data.field_ta_department});
            self.setState({ cutoff_application: response.data.field_ta_cutoff_application});
            self.setState({ earlyin_application: response.data.field_ta_earlyin_application});
            self.setState({ late_out_application: response.data.field_ta_lateout_application});
            self.setState({ pay_schedule_period: response.data.field_ta_pay_schedule_period});
            //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

    const { 
      company,
      employees,
      employees_type,
      sites,
      shift_group,
      departments,
      cutoff_application,
      earlyin_application,
      late_out_application,
      pay_schedule_period,
  } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Time Authorizations</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Time Authorizations</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="time_authorization">Personal Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Label</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="3">
                      <Label>From</Label>
                      <Form.Control value={this.state.fields["from"]} required ref="from" type="date" name="from" onChange={this.handleChange.bind(this, "from")}  />
                      <span style={{color: "red"}}>{this.state.errors["from"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>To</Label>
                      <Form.Control value={this.state.fields["to"]} required ref="to" type="date" name="to" onChange={this.handleChange.bind(this, "to")}  />
                      <span style={{color: "red"}}>{this.state.errors["to"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="time_authorization">Applied</Label>
              <Form.Row>
                  <Col md="4">
                      <Label>Employees</Label>
                      <Form.Control as="select" multiple value={this.state.fields["employee"]} required ref="employee" name="employee" onChange={this.getSelectValuesFromEmployee}>
                        {employees.map(employees => (
                          <option value={employees.id} key={employees.id}>
                            {employees.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="4">
                      <Label>Employees Types</Label>
                      <Form.Control as="select" multiple value={this.state.fields["employee_type"]} required ref="employee_type" name="employee_type" onChange={this.getSelectValuesFromEmployeeType}>
                        {employees_type.map(employees_type => (
                          <option value={employees_type.id} key={employees_type.id}>
                            {employees_type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Sites</Label>
                      <Form.Control as="select" multiple value={this.state.fields["site"]} required ref="site" name="site" onChange={this.getSelectValuesFromSites}>
                        {sites.map(sites => (
                          <option value={sites.id} key={sites.id}>
                            {sites.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Shift Groups</Label>
                      <Form.Control as="select" multiple value={this.state.fields["shift_groups"]} required ref="shift_groups" name="shift_groups" onChange={this.getSelectValuesFromShiftGroup}>
                        {shift_group.map(shift_group => (
                          <option value={shift_group.id} key={shift_group.id}>
                            {shift_group.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Departments</Label>
                      <Form.Control as="select" multiple value={this.state.fields["department"]} required ref="department" name="department" onChange={this.getSelectValuesFromDepartments}>
                        {departments.map(departments => (
                          <option value={departments.id} key={departments.id}>
                            {departments.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="time_authorization">Time Information</Label>
              <Form.Row>
                  <Col md="3">
                      <Label>Cutoff Absolute Time</Label>
                      <Form.Control value={this.state.fields["ucutoff_absolute_time"]} required ref="ucutoff_absolute_time" type="time" name="ucutoff_absolute_time" onChange={this.handleChange.bind(this, "ucutoff_absolute_time")}  />
                      <span style={{color: "red"}}>{this.state.errors["ucutoff_absolute_time"]}</span>
                  </Col>
                  <Col md="4">
                      <Label>Cutoff Absolute Time Is Day Before</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-catidb"
                        label=""
                        onChange={this.handleChangeucutoffabsolutetisdb}
                        name="ucutoff_absolute_tisdb"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>EarlyIn Cutoff Absolute Time</Label>
                      <Form.Control value={this.state.fields["uearlyin_coa_time"]} required ref="uearlyin_coa_time" type="time" name="uearlyin_coa_time" onChange={this.handleChange.bind(this, "uearlyin_coa_time")}  />
                      <span style={{color: "red"}}>{this.state.errors["uearlyin_coa_time"]}</span>
                  </Col>
                  <Col md="5">
                      <Label>EarlyIn Cutoff Absolute Time Is Day Before</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-ecatidb"
                        label=""
                        onChange={this.handleChangeuearlyincoatidb}
                        name="uearlyin_coa_tidb"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Late Out Cutoff Absolute Time</Label>
                      <Form.Control value={this.state.fields["ulateout_coa_time"]} required ref="ulateout_coa_time" type="time" name="ulateout_coa_time" onChange={this.handleChange.bind(this, "ulateout_coa_time")}  />
                      <span style={{color: "red"}}>{this.state.errors["ulateout_coa_time"]}</span>
                  </Col>
                  <Col md="5">
                      <Label>Late Out Cutoff Absolute Time Is Day Before</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-locatidb"
                        label=""
                        onChange={this.handleChangeulateoutcoatisda}
                        name="ulateout_coa_tisda"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Cutoff Relative Adjustment</Label>
                      <Form.Control value={this.state.fields["cutoff_relative_adj"]} required ref="cutoff_relative_adj" type="time" name="cutoff_relative_adj" onChange={this.handleChange.bind(this, "cutoff_relative_adj")}  />
                      <span style={{color: "red"}}>{this.state.errors["cutoff_relative_adj"]}</span>
                  </Col>
                  <Col md="5">
                      <Label>Cutoff Application</Label>
                      <Form.Control as="select" value={this.state.fields["cutoff_application"]} required ref="cutoff_application" name="cutoff_application" onChange={this.handleChange.bind(this, "cutoff_application")}>
                        {cutoff_application.map(cutoff_application => (
                          <option value={cutoff_application.id} key={cutoff_application.id}>
                            {cutoff_application.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="4">
                      <Label>Cutoff Increase Working Hours Only</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-ciwho"
                        label=""
                        onChange={this.handleChangecutoffiwho}
                        name="cutoff_iwho"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>EarlyIn Relative Adjustment</Label>
                      <Form.Control value={this.state.fields["earlyin_rel_adj"]} required ref="earlyin_rel_adj" type="time" name="earlyin_rel_adj" onChange={this.handleChange.bind(this, "earlyin_rel_adj")}  />
                      <span style={{color: "red"}}>{this.state.errors["earlyin_rel_adj"]}</span>
                  </Col>
                  <Col md="5">
                      <Label>EarlyIn Application</Label>
                      <Form.Control as="select" value={this.state.fields["earlyin_application"]} required ref="earlyin_application" name="earlyin_application" onChange={this.handleChange.bind(this, "earlyin_application")}>
                        {earlyin_application.map(earlyin_application => (
                          <option value={earlyin_application.id} key={earlyin_application.id}>
                            {earlyin_application.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="4">
                      <Label>EarlyIn Increase Working Hours Only</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-eiwho"
                        label=""
                        onChange={this.handleChangeearlyiniwho}
                        name="earlyin_iwho"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Late Out Relative Adjustment</Label>
                      <Form.Control value={this.state.fields["lateout_rel_adj"]} required ref="lateout_rel_adj" type="time" name="lateout_rel_adj" onChange={this.handleChange.bind(this, "lateout_rel_adj")}  />
                      <span style={{color: "red"}}>{this.state.errors["lateout_rel_adj"]}</span>
                  </Col>
                  <Col md="5">
                      <Label>Late Out Application</Label>
                      <Form.Control as="select" value={this.state.fields["lateout_application"]} required ref="lateout_application" name="lateout_application" onChange={this.handleChange.bind(this, "lateout_application")}>
                        {late_out_application.map(late_out_application => (
                          <option value={late_out_application.id} key={late_out_application.id}>
                            {late_out_application.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="4">
                      <Label>Late Out Increase Working Hours Only</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-ta-loiwho"
                        label=""
                        onChange={this.handleChangelateoutcoaiwho}
                        name="lateout_coa_iwho"
                      />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Overtime Maximum Gain</Label>
                      <Form.Control value={this.state.fields["overtime_maximum_gain"]} required ref="overtime_maximum_gain" type="time" name="overtime_maximum_gain" onChange={this.handleChange.bind(this, "overtime_maximum_gain")}  />
                      <span style={{color: "red"}}>{this.state.errors["overtime_maximum_gain"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Overtime Maximum Duration</Label>
                      <Form.Control value={this.state.fields["overtime_maximum_dur"]} required ref="overtime_maximum_dur" type="time" name="overtime_maximum_dur" onChange={this.handleChange.bind(this, "overtime_maximum_dur")}  />
                      <span style={{color: "red"}}>{this.state.errors["overtime_maximum_dur"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>


            <fieldset name="primaryInfo">
            <Label for="time_authorization">Miscellaneous</Label>
              <Form.Row>
                  <Col>
                      <Label>Approver</Label>
                      <Form.Control value={this.state.fields["approver"]} required ref="approver" type="text" name="approver" onChange={this.handleChange.bind(this, "approver")}  />
                      <span style={{color: "red"}}>{this.state.errors["approver"]}</span>
                  </Col>
                  <Col>
                      <Label>Pay Schedule Period</Label>
                      <Form.Control as="select" value={this.state.fields["pay_schedule_period"]} required ref="pay_schedule_period" name="pay_schedule_period" onChange={this.handleChange.bind(this, "pay_schedule_period")}>
                          <option value='-'>-</option>
                          {pay_schedule_period.map(pay_schedule_period => (
                            <option value={pay_schedule_period.id} key={pay_schedule_period.id}>
                              {pay_schedule_period.name}
                            </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Adjust Expected Arrival</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-pf-aea"
                        label=""
                        onChange={this.handleChangeadjexparrival}
                        name="adj_exp_arrival"
                      />
                  </Col>
                  <Col md="2">
                      <Label>Invalid</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-pf-invalid"
                        label=""
                        onChange={this.handleChangeinvalid}
                        name="invalid"
                      />
                  </Col>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-pf-active"
                        label=""
                        onChange={this.handleChangeActive}
                        name="active"
                      />
                  </Col>
              </Form.Row>
            </fieldset>

            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddTimeAuthorizations;