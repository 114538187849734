
import React from 'react';
import axios from 'axios';
import { getSessionID, getSessionName, getCompanyID } from '../../../Utils/Common';
import './EmployeeDetails.css';
import { Tab,Row,Col,Nav,Tabs} from 'react-bootstrap';
//import moment from 'moment';
import TimeLogsPerEmployee from '../EmployeeDetails/TimeLogsPerEmployee/TimeLogsPerEmployee';
import DailyTimeRecordsPerEmployee from './DailyTimeRecordsPerEmployee/DailyTimeRecordsPerEmployee';
import TimeAuthorizationsPerEmployee from './TimeAuthorizationsPerEmployee/TimeAuthorizationsPerEmployee';
import LeaveRecordsPerEmployee from './LeaveRecordsPerEmployee/LeaveRecordsPerEmployee';
import LoanRecords from './LoanRecords/LoanRecords';
import AdjustmentsEmployee from './Adjustments/Adjustments';
import SalaryInformation from './SalaryInformation/SalaryInformation';
import LeaveAvailable from './LeaveAvailable/LeaveAvailable';

class EmployeeDetails extends React.Component {
  constructor() {
      super();
      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;
    const id = this.props.match.params.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }



    axios.get('http://payroll.nikkolim.com/get_employees/employees/'+id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    },
    timeout: 180000,
  })
  .then((response) => {
    self.setState({ 
        employee_firstname: response.data.data.attributes.field_emp_fullname_given,
        employee_image: response.data.data.attributes.field_emp_picture_src,
        employee_middlename: response.data.data.attributes.field_emp_fullname_middle,
        employee_lastname: response.data.data.attributes.field_emp_fullname_family,
        employeeid: response.data.data.attributes.field_employee_id,
        employee_position: response.data.data.attributes.field_emp_position_label,
        employee_email: response.data.data.attributes.field_emp_email,
        employee_mobile: response.data.data.attributes.field_emp_mobile,
        employee_telephone: response.data.data.attributes.field_emp_telephone,
        employee_address: response.data.data.attributes.field_emp_address,
        employee_birthday: response.data.data.attributes.field_emp_birthdate,
        employee_gender: response.data.data.attributes.field_emp_gender,
        employee_status: response.data.data.attributes.field_emp_maritalstatus_label,
        employee_height: response.data.data.attributes.field_emp_height,
        employee_weight: response.data.data.attributes.field_emp_weight,
        employee_eye_color: response.data.data.attributes.field_emp_eye_color,
        employee_hair_color: response.data.data.attributes.field_emp_hair_color,
        employee_blood_type: response.data.data.attributes.field_emp_bloodtype_label,
        employee_complexion: response.data.data.attributes.field_emp_complexion,
        employee_retired: response.data.data.attributes.field_emp_retired,
        employee_company: response.data.data.attributes.field_emp_company_label,
        employee_hired_date: response.data.data.attributes.field_emp_hired_date,
        employee_pay_type: response.data.data.attributes.field_emp_pay_type_label,
        employee_department: response.data.data.attributes.field_emp_department_label,
        employee_salary_type: response.data.data.attributes.field_emp_salary_type_tid,
        employee_dayoff1: response.data.data.attributes.field_emp_day_off_1,
        employee_dayoff2: response.data.data.attributes.field_emp_day_off_2,
        employee_active: response.data.data.attributes.field_emp_active,
        employee_wh_status: response.data.data.attributes.field_emp_whstatus_label,
        employee_sss_type: response.data.data.attributes.field_emp_ssstype_label,
        employee_tax_deduction: response.data.data.attributes.field_emp_taxdeduction,
        employee_site: response.data.data.attributes.field_emp_site_label,
        employee_role: response.data.data.attributes.field_emp_role_label,
        employee_shift_group: response.data.data.attributes.field_emp_shift_group_label
    });
    //console.log(JSON.stringify(response.data.data.attributes.entity_id));
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
    const id = this.props.match.params.id;
    //   const { tableData } = this.state;
    const { employeeid,
            employee_firstname,
            employee_image,
            employee_middlename,
            employee_lastname,
            employee_position,
            employee_email,
            employee_mobile,
            employee_telephone,
            employee_address,
            employee_birthday,
            employee_gender,
            employee_status,
            employee_height,
            employee_weight,
            employee_eye_color,
            employee_hair_color,
            employee_blood_type,
            employee_complexion,
            employee_retired,
            employee_company,
            employee_hired_date,
            employee_pay_type,
            employee_department,
            employee_salary_type,
            employee_dayoff1,
            employee_dayoff2,
            employee_active,
            employee_wh_status,
            employee_sss_type,
            employee_tax_deduction,
            employee_site,
            employee_role,
            employee_shift_group
    } = this.state;
      return (
      <div>
        <div className="container-fluid">

            <div className="employee-details-handler col col-md-12">
                <div className="col col-md-2">
                    <div className="profile-img"><img src={employee_image} alt="" /></div>
                </div>
                <div className="col col-md-10">
                    <div className="profile-information">
                        <h2>{employee_firstname} {employee_middlename} {employee_lastname}</h2>
                        <p><i className="fa fa-user"></i> <span>{employeeid}</span></p>
                        <p><i className="fa fa-plus"></i> <span>{employee_position}</span></p>
                        <p><i className="fa fa-envelope"></i> <span>{employee_email}</span></p>
                        <p><i className="fa fa-phone"></i> <span>{employee_mobile}</span> | <span>{employee_telephone}</span></p>
                        <p><i className="fa fa-map-marker"></i> <span>{employee_address}</span></p>
                    </div>
                </div>
            </div>
{/* Tabs NEW */}
      <Tabs className='col col-md-12 horizontal-tabs' defaultActiveKey="overview" id="uncontrolled-tab-example">
            <Tab eventKey="overview" title="Overview">
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Employee ID:</div>
                  <div className="col col-md-7">{employeeid}</div>
                </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Full Name:</div>
                <div className="col col-md-7">{employee_firstname} {employee_middlename} {employee_lastname} </div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Email Address:</div>
                <div className="col col-md-7">{employee_email}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Birth Date:</div>
                <div className="col col-md-7">{employee_birthday}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Gender:</div>
                <div className="col col-md-7">{employee_gender}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Marital Status:</div>
                <div className="col col-md-7">{employee_status}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Height:</div>
                <div className="col col-md-7">{employee_height}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Weight:</div>
                <div className="col col-md-7">{employee_weight}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Eye Color:</div>
                <div className="col col-md-7">{employee_eye_color}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Hair Color:</div>
                <div className="col col-md-7">{employee_hair_color}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Blood Type:</div>
                <div className="col col-md-7">{employee_blood_type}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Complexion:</div>
                <div className="col col-md-7">{employee_complexion}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Retired:</div>
                <div className="col col-md-7">{employee_retired}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Company:</div>
                <div className="col col-md-7">{employee_company}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Hired Date:</div>
                <div className="col col-md-7">{employee_hired_date}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Mobile No.:</div>
                <div className="col col-md-7">{employee_mobile}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Telephone No.:</div>
                <div className="col col-md-7">{employee_telephone}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Pay Type:</div>
                <div className="col col-md-7">{employee_pay_type}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Department:</div>
                <div className="col col-md-7">{employee_department}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Position:</div>
                <div className="col col-md-7">{employee_position}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Salary Type:</div>
                <div className="col col-md-7">{employee_salary_type}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Day Off 1:</div>
                <div className="col col-md-7">{employee_dayoff1}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Day Off 2:</div>
                <div className="col col-md-7">{employee_dayoff2}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Active:</div>
                <div className="col col-md-7">{employee_active}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">WH Status:</div>
                <div className="col col-md-7">{employee_wh_status}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">SSS Type:</div>
                <div className="col col-md-7">{employee_sss_type}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Deduct Taxes?:</div>
                <div className="col col-md-7">{employee_tax_deduction}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Site:</div>
                <div className="col col-md-7">{employee_site}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Role:</div>
                <div className="col col-md-7">{employee_role}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Shift Group:</div>
                <div className="col col-md-7">{employee_shift_group}</div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Type:</div>
                <div className="col col-md-7"></div>
              </div>
              <div className="row">
                <div className="col col-md-3 entity-field-label name">Address:</div>
                <div className="col col-md-7">{employee_address}</div>
              </div>
            </Tab>
            <Tab eventKey="time-logs" title="Time Logs">
                <TimeLogsPerEmployee id={id}></TimeLogsPerEmployee>
            </Tab>
            <Tab eventKey="daily-time-records" title="Daily Time Records">
                <DailyTimeRecordsPerEmployee id={id}></DailyTimeRecordsPerEmployee>
            </Tab>
            {/* <Tab eventKey="time-authorizations" title="Time Authorizations">
                <TimeAuthorizationsPerEmployee id={id}></TimeAuthorizationsPerEmployee>
            </Tab> */}
            <Tab eventKey="loan-records" title="Loan Records">
                <LoanRecords id={id}></LoanRecords>
            </Tab>
            <Tab eventKey="leave-available" title="Leave Available">
                <LeaveAvailable id={id}></LeaveAvailable>
            </Tab>
            <Tab eventKey="leave-records" title="Leave Records">
                <LeaveRecordsPerEmployee id={id}></LeaveRecordsPerEmployee>
            </Tab>
            <Tab eventKey="adjustment-records" title="Adjustment Records">
                <AdjustmentsEmployee id={id}></AdjustmentsEmployee>
            </Tab>
            <Tab eventKey="salary-information" title="Salary Information">
                <SalaryInformation id={id}></SalaryInformation>
            </Tab>
        </Tabs>
        </div>
      </div>  
      );
  }
};



export default EmployeeDetails;
