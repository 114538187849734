import React, { useState } from 'react';
import axios from 'axios';
import { setUserSession } from './Utils/Common';
import logo from '../payrolllogo.png';
import payrollbackground from '../payroll-bg.jpg';
import './Login.min.css'
import './globalcss/bootstrap.min.css'


function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios.post('http://payroll.nikkolim.com/user_login/user_access/login', {
      username: username.value,
      password: password.value
    }).then(response => {
      setLoading(false);
      setUserSession(
        response.data.token,
        response.data.full_name,
        response.data.sessid,
        response.data.session_name,
        response.data.company_id,
        response.data.role_name,
        response.data.employee_picture,
        response.data.user_ref
        );
      props.history.push('/dashboard');
	console.log(JSON.stringify(response.data));
	// alert(error.response.data.message);
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError(error.response.data.message);
      else setError("Something went wrong. Please try again later.");
    });
  }

  return (
    <div className="login">
      {/* <header id="navbar" role="banner" className="main-header">
          <a className="logo" href="/temp-payroll/" title="Home">
          <span className="logo-lg">
            <div>
              <img src={logo} alt="Logo" />;
            </div>
          </span>
        </a>
      </header>

      <div className="login-form">
        <div>
          Username<br />
          <input type="text" {...username} autoComplete="new-password" />
        </div>
        <div style={{ marginTop: 10 }}>
          Password<br />
          <input type="password" {...password} autoComplete="new-password" />
        </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
        <input type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br />
      </div> */}


<div className="user-login-5">
	<div className="row bs-reset">
		<div className="col-md-6 login-container bs-reset">
			{/* <img className="login-logo login-6" src="../assets/pages/img/login/login-invert.png" /> */}
      <img className="login-logo login-6" src={logo} alt="Logo" />
			<div className="login-content">
				<h1>Payroll Web System Login</h1>
				<p> Lorem ipsum dolor sit amet, coectetuer adipiscing elit sed diam nonummy et nibh euismod aliquam erat volutpat. Lorem ipsum dolor sit amet, coectetuer adipiscing. </p>
				<form action="javascript:;" className="login-form" method="post">
					<div className="alert alert-danger display-hide">
						<button className="close" data-close="alert"></button>
						<span>Enter any username and password. </span>
					</div>
					<div className="row">
						<div className="col-xs-6">
							{/* <input className="form-control form-control-solid placeholder-no-fix form-group" type="text" autocomplete="off" placeholder="Username" name="username" required/> */}
              <input className="form-control form-control-solid placeholder-no-fix form-group" type="text" autocomplete="off"  placeholder="Username" name="username" required {...username} autoComplete="new-password" />
            </div>  
						<div className="col-xs-6">
							{/* <input className="form-control form-control-solid placeholder-no-fix form-group" type="password" autocomplete="off" placeholder="Password" name="password" required/>  */}
              <input className="form-control form-control-solid placeholder-no-fix form-group" type="password" autocomplete="off" placeholder="Password" name="password" required {...password} autoComplete="new-password" />
            </div>
					</div>
					<div className="row">
						<div className="col-sm-4">
							<label className="rememberme mt-checkbox mt-checkbox-outline">
								<input type="checkbox" name="remember" value="1" /> Remember me
								<span></span>
							</label>
						</div>
						<div className="col-sm-8 text-right">
							{/* <div className="forgot-password">
								<a href="javascript:;" id="forget-password" className="forget-password">Forgot Password?</a>
							</div> */}
							{/* <button className="btn blue" type="submit">Sign In</button> */}
							<div className="error">{error}</div>
			  {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}
              <input className="btn blue" type="button" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br />
						</div>
					</div>
				</form>
				{/* <!-- BEGIN FORGOT PASSWORD FORM --> */}
				{/* <form className="forget-form" action="javascript:;" method="post">
					<h3>Forgot Password ?</h3>
					<p> Enter your e-mail address below to reset your password. </p>
					<div className="form-group">
						<input className="form-control placeholder-no-fix" type="text" autocomplete="off" placeholder="Email" name="email" /> </div>
					<div className="form-actions">
						<button type="button" id="back-btn" className="btn blue btn-outline">Back</button>
						<button type="submit" className="btn blue uppercase pull-right">Submit</button>
					</div>
				</form> */}
				{/* <!-- END FORGOT PASSWORD FORM --> */}
			</div>
			<div className="login-footer">
				<div className="row bs-reset">
					<div className="col-xs-5 bs-reset">
						{/* <ul className="login-social">
							<li>
								<a href="javascript:;">
									<i className="icon-social-facebook"></i>
								</a>
							</li>
							<li>
								<a href="javascript:;">
									<i className="icon-social-twitter"></i>
								</a>
							</li>
							<li>
								<a href="javascript:;">
									<i className="icon-social-dribbble"></i>
								</a>
							</li>
						</ul> */}
					</div>
					<div className="col-xs-7 bs-reset">
						<div className="login-copyright text-right">
							<p>Copyright &copy; Payroll 2020</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-6 bs-reset">
			<div className="login-bg"> <img className="background" src={payrollbackground} alt="Logo" /></div>
		</div>
	</div>
</div>


    </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;