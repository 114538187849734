import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddSalaryInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      team :'' ,
      country: '',
      employee:[],
      formula:[],
  };

    this.toggle = this.toggle.bind(this);
	  this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeGender(event) {
    this.setState({gender: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
  }


  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_salary_information/employee_salary_information_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
            self.setState({ employee: response.data.field_empsal_employee});
            self.setState({ formula: response.data.field_empsal_formula});
        //  self.setState({ adjustment_salary: response.data.field_adjustment_salary});
        //  self.setState({ adjustment_pay_record: response.data.field_adjustment_pay_record});
         // console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        employee,
        formula,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Salary Information</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Salary Information</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="adjustment">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md='4'>
                      <Label>Entry Date</Label>
                      <Form.Control type="date" />
                </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Name</Label>
                      <Form.Control type="text" />
                  </Col>
                  <Col>
                      <Label>Formula</Label>
                      <Form.Control as="select" onChange={this.searchSubmit}>
                        {formula.map(formula => (
                          <option value={formula.id} key={formula.id}>
                            {formula.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-si-active"
                        label=""
                      />
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="payment-details">
            <Label for="adjustment">Rate Information</Label>
              <Form.Row>

              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Save</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddSalaryInformation;