
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import AddLoanRecords from './addLoanRecords';
// import moment from 'moment';


class LoanRecords extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    // const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const id = this.props.id;
    
    axios.get('http://payroll.nikkolim.com/get_loans/loans_employee/'+id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      // const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_loan_employee_label',
        text: 'Name',
      }, {
        dataField: 'field_loan_title',
        text: 'Loan Title'
      }, {
        dataField: 'field_loan_salary',
        text: 'Loan Salary'
      }, {
        dataField: 'field_loan_type',
        text: 'Loan Type'
      },{
        dataField: 'field_loan_entry_date',
        text: 'Loan Date'
      },{
        dataField: 'field_loan_original_amount',
        text: 'Loan Original Amount'
      },{
        dataField: 'field_loan_amount',
        text: 'Loan Amount'
      },{
        dataField: 'field_loan_balance',
        text: 'Loan Balance'
      },{
        dataField: 'field_loan_deduction',
        text: 'Loan Deduction'
      },{
        dataField: 'field_loan_monthly_target',
        text: 'Loan Monthly Target'
      },{
        dataField: 'field_loan_start_date',
        text: 'Loan Start Date'
      },{
        dataField: 'field_loan_notes',
        text: 'Note'
      },{
        dataField: 'field_loan_active',
        text: 'Active'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Loan Records</h1>
        <div className="add-button"><AddLoanRecords employee_Id={this.props.id}></AddLoanRecords></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_loan_employee_label' >Name</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_loan_title' filter={textfilter}>Loan Title</TableHeaderColumn>
          <TableHeaderColumn dataField='field_loan_salary'>Loan Salary</TableHeaderColumn>
          <TableHeaderColumn dataField='field_loan_type'>Loan Type</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_entry_date'>Loan Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_original_amount' filter={textfilter}>Orginial Amount</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_amount' filter={textfilter}>Loan Amount</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_balance' filter={textfilter}>Loan Balance</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_deduction' filter={textfilter}>Loan Deduction</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_monthly_target' filter={textfilter}>Monthly Targe</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_start_date' filter={textfilter}>Start Date</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_active' filter={textfilter}>Active</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_loan_notes' filter={textfilter}>Notes</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default LoanRecords;

