
import React from 'react';
// import { Tab,Tabs,Row,Col,Nav} from 'react-bootstrap';
import { Tab,Tabs} from 'react-bootstrap';
import PayrollUserShiftDefinitions from './Shift Definitions/ShiftDefinitions';
import PayrollUserShiftGroups from './ShiftGroups/ShiftGroups';
import PayrollUserShiftSets from './ShiftSets/ShiftSets';
import PayrollUserShiftAssignments from './ShiftAssignments/ShiftAssignments';
import PayrollUserShiftOverrides from './ShiftOverrides/ShiftOverrides';

class PayrollAdminUserShiftMaintenance extends React.Component {
  render () {
      return (
      <div>
        <h1 class="h3 mb-0 text-gray-800">Ship Maintenance</h1>
        <Tabs defaultActiveKey="shift-definitions" id="uncontrolled-tab-example">
            <Tab eventKey="shift-definitions" title="Shift Definitions">
                <PayrollUserShiftDefinitions></PayrollUserShiftDefinitions>
            </Tab>
            <Tab eventKey="shift-groups" title="Shift Groups">
                <PayrollUserShiftGroups></PayrollUserShiftGroups>
            </Tab>
            <Tab eventKey="shift-sets" title="Shift Sets">
                <PayrollUserShiftSets></PayrollUserShiftSets>
            </Tab>
            <Tab eventKey="shift-assignments" title="Shift Assignments">
                <PayrollUserShiftAssignments></PayrollUserShiftAssignments>
            </Tab>
            <Tab eventKey="shift-overrides" title="Shift Overrides">
                <PayrollUserShiftOverrides></PayrollUserShiftOverrides>
            </Tab>
        </Tabs>
      </div>  
      );
  }
};

export default PayrollAdminUserShiftMaintenance;

