
import React from 'react';
import axios from 'axios';
import $ from 'jquery';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getSessionID, getSessionName, getUserID } from '../../../../Utils/Common';
import AddSalaryInformation from './addSalaryInformation';



class SalaryInformation extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const employee_id = getUserID();
    if (!employee_id) {
      return;
    }   


    axios.get('http://payroll.nikkolim.com/get_employee_salary_informations/employee_salary_information/'+id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   

      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({ tableData: data });
        //console.log(JSON.stringify(val2.attributes));
      })
      
    });
     //console.log(JSON.stringify(response));
  }, (error) => {
    console.log(error);
  });
  
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_empsal_employee',
        text: 'Name'
      },{
        dataField: 'field_empsal_name',
        text: 'Type',
      },{
        dataField: 'field_empsal_entry_date',
        text: 'Entry Date',
      },{
        dataField: 'field_empsal_active',
        text: 'Active',
      },{
        dataField: 'entity_id',
        text: 'ID',
      }];

      return (
      <div>
        <h1 className="h3 mb-0 text-gray-800">Salary Information</h1>
        {/* <div className="add-button"><AddDepartment></AddDepartment></div> */}
        <div className="add-button"><AddSalaryInformation employee_Id={this.props.id}></AddSalaryInformation></div>
        <BootstrapTable responsive  wrapperClasses='table-responsive' bootstrap4 version='4' keyField='field_employee_id' columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_empsal_employee' filter={textfilter}>Employee</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_empsal_name' filter={textfilter}>Name</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_empsal_entry_date' filter={textfilter}>Entry Date</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_empsal_active' filter={textfilter}>Active</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default SalaryInformation;

