
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID, getUserID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import AddLeaveAvailable from './addLeaveAvailable';

// import moment from 'moment';


class LeaveAvailable extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const user_id = getUserID();
    if (!user_id) {
      return;
    }
    
    axios.get('http://payroll.nikkolim.com/get_leave_available/employee_leave_available/'+id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {
        $.each(val, function( i, val2 ){
            data.push(val2.attributes);
            self.setState({ 
              tableData: data, 
              employee_Id: val2.attributes.field_leaveavail_employee_target_id
            });       
            //console.log(JSON.stringify(data));
        });
    });
    //console.log(JSON.stringify(response.data));
    }, (error) => {
      console.log(error);
    });
  }

  

  render () {
    
      const { 
        tableData,
        employee_Id
      } = this.state;

      const textfilter = { type: 'TextFilter', delay: 1000};
      // const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_leaveavail_employee_label',
        text: 'Name',
      }, {
        dataField: 'field_leaveavail_type_label',
        text: 'Type'
      }, {
        dataField: 'field_leaveavail_total',
        text: 'Total'
      }, {
        dataField: 'field_leaveavail_balance',
        text: 'Balance'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];

      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Leave Available </h1>
        <div className="add-button"><AddLeaveAvailable employee_Id={this.props.id}></AddLeaveAvailable></div>
        {/* <div className="add-button"><AddLeaveAvailable id={employee_Id}></AddLeaveAvailable></div> */}
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_leaveavail_employee_label' >Name</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_leaveavail_type_label' filter={textfilter}>Type</TableHeaderColumn>
          <TableHeaderColumn dataField='field_leaveavail_total'>Total</TableHeaderColumn>
          <TableHeaderColumn dataField='field_leaveavail_balance'>Balance</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default LeaveAvailable;

