
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import moment from 'moment';
import AddTimeLogs from './addTimeLogs';


class TimeLogsPerEmployee extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const id = this.props.id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const firstday = function(y,m){
        return  new Date(y, m +1).getDate();
    }
    const lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
    }

    const today = Date.now();
    const month = new Intl.DateTimeFormat('en-US', {month: '2-digit'}).format(today);
    const year = new Intl.DateTimeFormat('en-US', {year: 'numeric'}).format(today);

    const Firstday = firstday(year,month);
    const Lastday = lastday(year,month);
    
    const firstday_updated_month_day_year_today = month + '/' + Firstday + '/' + year;
    const lastday_updated_month_day_year_today = month + '/' + Lastday + '/' + year;

    const Firstdayformated = moment(firstday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");
    const Lastdayformated = moment(lastday_updated_month_day_year_today).format("YYYY-MM-DD, h:mm:ss");

    var FirstdayUnixTimestamp = Math.round(new Date(Firstdayformated).getTime()/1000);
    var LastdayUnixTimestamp = Math.round(new Date(Lastdayformated).getTime()/1000);

    
    axios.get('http://payroll.nikkolim.com/get_time_logs/employee_time_logs/'+id+'/'+FirstdayUnixTimestamp+'/'+LastdayUnixTimestamp, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_time_employee_label',
        text: 'Employee',
      }, {
        dataField: 'field_time_entry_type',
        text: 'Entry Type'
      }, {
        dataField: 'field_time_date',
        text: 'Date'
      }, {
        dataField: 'field_time_log',
        text: 'Time Log'
      },{
        dataField: 'field_time_device_label',
        text: 'Device'
      },{
        dataField: 'field_time_site_label',
        text: 'Site'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <div className="add-button"><AddTimeLogs employee_Id={this.props.id}></AddTimeLogs></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_time_employee_label' >Employee</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_time_entry_type' filter={textfilter}>Entry Type</TableHeaderColumn>
          <TableHeaderColumn dataField='field_time_date' filter={datefilter}>Date</TableHeaderColumn>
          <TableHeaderColumn dataField='field_time_log'>Time Log</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_time_site_label' filter={textfilter}>Site</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_time_device_label' filter={textfilter}>Device</TableHeaderColumn>
          {/* <TableHeaderColumn dataField='entity_id' text='View Details' dataFormat={this.valueFormatter} >Operations</TableHeaderColumn> */}
        </BootstrapTable>
      </div>  
      );
  }
};



export default TimeLogsPerEmployee;

