import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';
import Menu from '../MainMenu/main-menu';
import Footer from '../Footer/Footer';
import TopHeader from '../Topheader/Topheader';


// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <div className="App">
    <div className="contents">
      <div id="wrapper">
        <Menu/>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TopHeader/>
            <div className="container-fluid">
            <Route {...rest} render={(props) => getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}/>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PrivateRoute;