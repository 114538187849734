
import React from 'react';
import axios from 'axios';
import $ from 'jquery';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { getSessionID, getSessionName, getCompanyID } from '../../../../../Utils/Common';
import AddShiftSets from './addShiftSets';

class PayrollUserShiftSets extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }

    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    
    axios.get('http://payroll.nikkolim.com/get_shift_sets/shift_sets_company/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({
            tableData: data
        });
        //console.log(JSON.stringify(data));
      })
      
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      //const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_set_label',
        text: 'Label',
      }, {
        dataField: 'field_set_sunday',
        text: 'Sunday'
      },{
        dataField: 'field_set_monday',
        text: 'Monday'
      },{
        dataField: 'field_set_tuesday',
        text: 'Tuesday'
      },{
        ataField: 'field_set_wednesday',
        text: 'Wednesday'
      },{
        ataField: 'field_set_thursday',
        text: 'Thursday'
      },{
        ataField: 'field_set_friday',
        text: 'Friday'
      },{
        ataField: 'field_set_saturday',
        text: 'Saturday'
      },{
        ataField: 'field_set_default',
        text: 'Default'
      },{
        ataField: 'field_set_active',
        text: 'Active'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Shift Sets</h1>
        <div className="add-button"><AddShiftSets></AddShiftSets></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_set_label' filter={textfilter}>Label</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_set_sunday' filter={textfilter}>Sunday</TableHeaderColumn>
          <TableHeaderColumn dataSort  dataField='field_set_monday' filter={textfilter}>Monday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_tuesday'filter={textfilter} >Tuesday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_wednesday' filter={textfilter}>Wednesday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_thursday' filter={textfilter}>Thursday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_friday' filter={textfilter}>Friday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_saturday' filter={textfilter}>Saturday</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_default' filter={textfilter}>Default</TableHeaderColumn>
          <TableHeaderColumn dataField='field_set_active' filter={textfilter}>Active</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default PayrollUserShiftSets;

