
import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import AddEmployee from './addEmployee';


class ListofEmployees extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }   

    axios.get('http://payroll.nikkolim.com/get_employees/employees_company/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   

      $.each(val, function( i, val2 ){
        data.push(val2.attributes);
        self.setState({ tableData: data });
         //console.log(JSON.stringify(val2.attributes));
      })
      
    });
    //console.log(JSON.stringify(data));
  }, (error) => {
    console.log(error);
  });
  
  }


  valueFormatter(cell, row){
    return (
      <div><a className="btn btn-secondary" href={"employees-details/"+cell}>View Details</a></div>
      );
  }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
      const datefilter = { type: 'DateFilter', delay: 1000};
      
      let datalist = [{
        dataField: 'field_employee_id',
        text: 'Company ID'
      }, {
        dataField: 'field_emp_fullname_label',
        text: 'Full Name',
        filter: textFilter()
      }, {
        dataField: 'field_emp_email',
        text: 'Email Address'
      }, {
        dataField: 'field_emp_role_label',
        text: 'Role'
      },{
        dataField: 'field_emp_department_label',
        text: 'Department'
      },{
        dataField: 'field_emp_position_label',
        text: 'Position'
      },{
        dataField: 'field_emp_hired_date',
        text: 'Hired Date'
      },{
        dataField: 'entity_id',
        text: 'View Details'
      }];

      return (
        
      <div>
        <h1 className="h3 mb-0 text-gray-800">List of Employees</h1>
        <div className="add-button"><AddEmployee></AddEmployee></div>
        <BootstrapTable wrapperClasses='table-responsive' overlay={ overlayFactory() } bootstrap4 version='4' loading={ true } keyField='field_employee_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort dataField='field_employee_id' filter={textfilter}>Company ID</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort  dataField='field_emp_fullname_label' filter={textfilter}>Full Name</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort dataField='field_emp_email' filter={textfilter}>Email Address</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort dataField='field_emp_role_label' filter={textfilter}>Role</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort dataField='field_emp_department_label' filter={textfilter}>Department</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataSort dataField='field_emp_position_label' filter={textfilter}>Position</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataField='field_emp_hired_date' filter={datefilter}>Hired Date</TableHeaderColumn>
          <TableHeaderColumn width={window.innerWidth < 768 ? '0' : '20%'} dataField='entity_id' text='View Details' dataFormat={this.valueFormatter} >Operations</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default ListofEmployees;

