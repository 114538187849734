import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../Utils/Common';



class AddLoans extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      team :'' ,
      country: '',
      employee:[],
  };

    this.toggle = this.toggle.bind(this);
	this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeGender(event) {
    this.setState({gender: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
  }


  componentDidMount () {
    let self = this;

    //const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'company_id': company_id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_adjustments/adjustment_form', postData, axiosConfig)
    .then((response) => {
        //  self.setState({ company_id: company_id_login});
         //self.setState({ employee: response.data.field_adjustment_employee});
         console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        employee,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Loan</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Loan</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="adjustment">Basic Information</Label>
              <Form.Row>
                  <Col md="7">
                      <Label>Name</Label>
                      <Form.Control type="text" />
                  </Col>
                  <Col>
                      <Label>Employee</Label>
                      {/* <Form.Control as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control> */}
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Type</Label>
                      {/* <Form.Control as="select" onChange={this.searchSubmit}>
                        {adjustment_type.map(adjt => (
                          <option value={adjt.id} key={adjt.id}>
                            {adjt.name}
                          </option>
                          ))}
                      </Form.Control> */}
                  </Col>
                  <Col>
                      <Label>Salary</Label>
                      {/* <Form.Control as="select" onChange={this.searchSubmit}>
                        {adjustment_salary.map(adjtss => (
                          <option value={adjtss.id} key={adjtss.id}>
                            {adjtss.name}
                          </option>
                          ))}
                      </Form.Control> */}
                  </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                      <Label>Notes</Label>
                      <Form.Control as="textarea" />
                </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="payment-details">
            <Label for="adjustment">Payment Details</Label>
              <Form.Row>
                <Col>
                      <Label>Payment Date</Label>
                      <Form.Control type="date" />
                </Col>
                <Col>
                      <Label>Amount</Label>
                      <Form.Control type="decimal" />
                </Col>
                <Col>
                      <Label>Pay Record</Label>
                      {/* <Form.Control as="select" onChange={this.searchSubmit}>
                        {adjustment_pay_record.map(apr => (
                          <option value={apr.id} key={apr.id}>
                            {apr.name}
                          </option>
                        ))}
                      </Form.Control> */}
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Save</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddLoans;