import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import { getSessionID, getSessionName, getCompanyID } from '../../../../Utils/Common';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import AddSalaryRateKeys from './addSalaryRateKeys';


class SalaryRateKeys extends React.Component {
  constructor () {
      super();

      this.state = {
        tableData:[]
      };
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    
    axios.get('http://payroll.nikkolim.com//get_company_salary_rate_key/company_salary_keys/'+company_id, {
    headers: {
      'Content-Type': 'application/json',
      "Authentication": session_Name+"="+session_ID,
    }
  })
  .then((response) => {
    const data = [];
    $.each( response.data, function( i, val ) {   
        //console.log(JSON.stringify(val));
        $.each(val, function( i, val2 ){
            data.push(val2.attributes);
            self.setState({
                tableData: data
            });
            
          })
          //console.log(JSON.stringify(data));
    });
    //console.log(JSON.stringify(response.data));
  }, (error) => {
    console.log(error);
  });
  
  }

//   valueFormatter(cell, row){
//     return (
//       <div><a className="btn btn-primary" href={"employees-details/"+cell}>View Details</a></div>
//       );
//   }

  render () {
      const { tableData } = this.state;
      const textfilter = { type: 'TextFilter', delay: 1000};
    //   const datefilter = { type: 'DateFilter', delay: 1000};

      let datalist = [{
        dataField: 'field_salratekey_company',
        text: 'Company',
      },{
        dataField: 'field_salratekey_name',
        text: 'Name'
      },{
        dataField: 'field_salratekey_default_value',
        text: 'Value'
      },{
        dataField: 'field_salratekey_sequence',
        text: 'Sequence'
      },{
        dataField: 'field_salratekey_required',
        text: 'Required'
      },{
        dataField: 'field_salratekey_active',
        text: 'Active'
      },{
        dataField: 'entity_id',
        text: 'ID'
      }];
      
      return (
        
      <div>
        <h1 class="h3 mb-0 text-gray-800">Salary Rate Keys</h1>
        <div className="add-button"><AddSalaryRateKeys></AddSalaryRateKeys></div>
        <BootstrapTable responsive wrapperClasses='table-responsive' bootstrap4 version='4' keyField='entity_id' filter={ filterFactory() } columns={ datalist } data={ tableData } pagination={ paginationFactory() } search>
          <TableHeaderColumn dataSort dataField='field_salratekey_name' filter={textfilter}>Name</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_salratekey_default_value' filter={textfilter}>Value</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_salratekey_sequence' filter={textfilter}>Sequence</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_salratekey_company' filter={textfilter}>Company</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_salratekey_required' filter={textfilter}>Required</TableHeaderColumn>
          <TableHeaderColumn dataSort dataField='field_salratekey_active' filter={textfilter}>Active</TableHeaderColumn>
        </BootstrapTable>
      </div>  
      );
  }
};



export default SalaryRateKeys;

