import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getToken, getCompanyID } from '../../../../Utils/Common';

class AddPayType extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      active:'',
      fields: {},
      errors: {}
    };

    this.toggle = this.toggle.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleChangeSystem(event) {
    if(event.target.checked == true){
      this.setState({system: '1'});
    }
    else{
      this.setState({system: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const loan_types = {
        field_paytype_name: this.state.fields["name"],
        field_paytype_company: company_id,
        field_paytype_active: this.state.active,
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_pay_types/pay_types`, loan_types, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    var postData = {
      'company_id': company_id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/company_pay_types/pay_type_form', postData, axiosConfig)
    .then((response) => {
        self.setState({ company: response.data.field_paytype_company});
        //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

    const { 
      company,
  } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Pay Type</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Pay Type</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            {/* <Label for="employee_information">Personal Information</Label> */}
              <Form.Row>
                  <Col>
                      <Label>Name</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" placeholder="Name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-pt-active"
                        label=""
                        onChange={this.handleChangeActive}
                        name="active"
                      />
                </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddPayType;