import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddDailyTimeRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      team :'' ,
      country: '',
      employee:[],
      day:[],
      error:[],
  };

    this.toggle = this.toggle.bind(this);
	this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeGender(event) {
    this.setState({gender: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
  }


  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_time_records/time_record_form', postData, axiosConfig)
    .then((response) => {
                self.setState({ employee: response.data.field_timerecord_employee});
                self.setState({ day: response.data.field_timerecord_day});
                self.setState({ error: response.data.field_timerecord_errors});
             // console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        employee,
        day,
        error,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Daily Time Record</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Daily Time Record</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="daily_time_records">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="3">
                      <Label>Date</Label>
                      <Form.Control type="date" />
                  </Col>
                  <Col md="3">
                      <Label>Day</Label>
                      <Form.Control as="select" onChange={this.searchSubmit}>
                        {day.map(day => (
                          <option value={day.id} key={day.id}>
                            {day.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="daily_time_records">Time Info</Label>
              <Form.Row>
                  <Col md="3">
                      <Label>Expected Hour</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Regular</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Overtime Hours</Label>
                      <Form.Control type="time" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Night Differential</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Night Differential OT</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Late Hours</Label>
                      <Form.Control type="time" />
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="daily_time_records">Actual</Label>
              <Form.Row>
                  <Col md="3">
                      <Label>Actual Regular</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Actual Overtime</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Actual Late</Label>
                      <Form.Control type="time" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                      <Label>Actual Night Deferential</Label>
                      <Form.Control type="time" />
                  </Col>
                  <Col md="3">
                      <Label>Actual Night Deferential OT</Label>
                      <Form.Control type="time" />
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
            <Label for="daily_time_records">Miscellaneous</Label>
              <Form.Row>
                  <Col md="3">
                      <Label>Error</Label>
                      <Form.Control as="select" onChange={this.searchSubmit}>
                        {error.map(error => (
                          <option value={error.id} key={error.id}>
                            {error.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Warnings</Label>
                      <Form.Control as="textarea" />
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	<Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Save</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddDailyTimeRecord;