import React, { Component } from 'react'
import $ from 'jquery'
import '../Template/sb-admin-2.css';
import '../Template/sb-admin-2.min.css';
// import handleLogout from '../Dashboard';
import { getUser, getUserImage } from '../Utils/Common';
import LogoutButton from './modal-logout';
// import Logout from './logout';
// import Example from './modal-logout';

// import Modal from 'react-modal';
// import { Button } from 'react-bootstrap';

class TopHeader extends Component {

  constructor(props, context) {
		super(props, context);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false,
		};
	}

  componentDidMount() {
    $(document).on('scroll', function() {
      var scrollDistance = $(this).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
    }); 
    
  }

  handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

  render() {

    const username = getUser();
    if (!username) {
      return;
    }

    const user_image = getUserImage();
    if (!user_image) {
      return;
    }

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <i className="fa fa-bars"></i>
        </button>
        <ul className="navbar-nav ml-auto">
          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow">
            {/* <Button variant="primary" onClick={this.handleShow}>
                  Launch demo modal
                </Button>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Close
                              </Button>
                      <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                              </Button>
                    </Modal.Footer>
                  </Modal> */}
            {/* <a className="nav-link dropdown-toggle" href="/#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> */}
            <div className="nav-link">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{username}</span>
            <img alt="" class="img-profile rounded-circle" src={user_image}></img>
              {/* <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a className="dropdown-item" href="javascript">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </a>
                <a className="dropdown-item" href="javascript">
                  <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </a>
                <a className="dropdown-item" href="javascript">
                  <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </a>
                <div className="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div> */}
            {/* </a> */}</div>
          </li>
          <div className ="logout-button-handler">
            <LogoutButton></LogoutButton>
          </div>
        </ul>
      </nav>
    )
  }
}

export default TopHeader
