import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionID, getSessionName, getCompanyID, getToken } from '../../../../Utils/Common';


class AddTimeLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      entry_type:[],
      employee:[],
      site:[],
      device:[],
      original_type:[],
      fields: {},
      errors: {}
  };

  this.toggle = this.toggle.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //date
    if(!fields["date"]){
       formIsValid = false;
       errors["date"] = "Cannot be empty";
    }

    //time
    if(!fields["time"]){
      formIsValid = false;
      errors["time"] = "Cannot be empty";
   }

    //device
    if(!fields["type"]){
      formIsValid = false;
      errors["type"] = "Cannot be empty";
    }

   //site
   if(!fields["site"]){
    formIsValid = false;
    errors["site"] = "Cannot be empty";
    }

    //site_id
    if(!fields["site_id"]){
      formIsValid = false;
      errors["site_id"] = "Cannot be empty";
    }

    //device
    if(!fields["device"]){
      formIsValid = false;
      errors["device"] = "Cannot be empty";
    }

    //device_id
    if(!fields["device_id"]){
      formIsValid = false;
      errors["device_id"] = "Cannot be empty";
    }

    //employee_device_id
    if(!fields["employee_device_id"]){
      formIsValid = false;
      errors["employee_device_id"] = "Cannot be empty";
    }

    //original_type
    if(!fields["original_type"]){
      formIsValid = false;
      errors["original_type"] = "Cannot be empty";
    }

    //original_date
    if(!fields["original_date"]){
      formIsValid = false;
      errors["original_date"] = "Cannot be empty";
    }

    //original_time
    if(!fields["original_time"]){
      formIsValid = false;
      errors["original_time"] = "Cannot be empty";
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }

      const id = this.props.employee_Id;
  
      const time_logs = {
        field_time_date: this.state.fields["date"],
        field_time_log: this.state.fields["time"],
        field_time_entry_type: this.state.fields["type"],
        field_time_employee: id,
        field_time_site: this.state.fields["site"],
        field_time_site_id: this.state.fields["site_id"],
        field_time_device: this.state.fields["device"],
        field_time_device_id: this.state.fields["device_id"],
        field_time_empdeviceid: this.state.fields["employee_device_id"],
        field_time_original_type: this.state.fields["original_type"],
        field_time_original_date: this.state.fields["original_date"],
        field_time_original_log: this.state.fields["original_time"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_time_logs/time_logs/`, time_logs, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }


  componentDidMount () {
    let self = this;

    const id = this.props.employee_Id;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }

    var postData = {
      'employee_id': id
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employee_time_logs/time_log_form', postData, axiosConfig)
    .then((response) => {
        //   self.setState({ company_id: company_id_login});
             self.setState({ entry_type: response.data.field_time_entry_type});
             self.setState({ employee: response.data.field_time_employee});
             self.setState({ site: response.data.field_time_site});
             self.setState({ device: response.data.field_time_device});
             self.setState({ original_type: response.data.field_time_original_type});
             //console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {
    const { 
        entry_type,
        employee,
        site,
        device,
        original_type,
    } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Time Logs</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Time Logs</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="timelogs">Basic Information</Label>
              <Form.Row>
                  <Col md="3">
                      <Label>Date</Label>
                      <Form.Control value={this.state.fields["date"]} required ref="date" type="date" name="date" onChange={this.handleChange.bind(this, "date")}  />
                      <span style={{color: "red"}}>{this.state.errors["date"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Time</Label>
                      <Form.Control value={this.state.fields["time"]} required ref="time" type="time" name="time" onChange={this.handleChange.bind(this, "time")}  />
                      <span style={{color: "red"}}>{this.state.errors["time"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Type</Label>    
                      <Form.Control as="select" value={this.state.fields["type"]} required ref="type" name="type" onChange={this.handleChange.bind(this, "type")}>
                        {entry_type.map(entry_type => (
                          <option value={entry_type.id} key={entry_type.id}>
                            {entry_type.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["type"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Employee</Label>
                      <Form.Control disabled as="select" onChange={this.searchSubmit}>
                        {employee.map(employee => (
                          <option value={employee.id} key={employee.id}>
                            {employee.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Site</Label>
                      <Form.Control as="select" value={this.state.fields["site"]} required ref="site" name="site" onChange={this.handleChange.bind(this, "site")}>
                        <option value='-'>-</option>
                        {site.map(site => (
                          <option value={site.id} key={site.id}>
                            {site.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["site"]}</span>
                  </Col>
                  <Col>
                      <Label>Site ID</Label>
                      <Form.Control type="text" value={this.state.fields["site_id"]} required ref="site_id" name="site_id" onChange={this.handleChange.bind(this, "site_id")} />
                      <span style={{color: "red"}}>{this.state.errors["site_id"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Device</Label>
                      <Form.Control as="select" value={this.state.fields["device"]} required ref="device" name="device" onChange={this.handleChange.bind(this, "device")}>
                        {device.map(device => (
                          <option value={device.id} key={device.id}>
                            {device.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["device"]}</span>
                  </Col>
                  <Col>
                      <Label>Device ID</Label>
                      <Form.Control type="text" value={this.state.fields["device_id"]} required ref="device_id" name="device_id" onChange={this.handleChange.bind(this, "device_id")} />
                      <span style={{color: "red"}}>{this.state.errors["device_id"]}</span>
                  </Col>
                  <Col>
                      <Label>Employee Device ID</Label>
                      <Form.Control type="text" value={this.state.fields["employee_device_id"]} required ref="employee_device_id" name="employee_device_id" onChange={this.handleChange.bind(this, "employee_device_id")} />
                      <span style={{color: "red"}}>{this.state.errors["employee_device_id"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="amout-details">
            <Label for="timelogs">Others</Label>
              <Form.Row>
                <Col md="2">
                      <Label>Original Type</Label>
                      <Form.Control as="select" value={this.state.fields["original_type"]} required ref="original_type" name="original_type" onChange={this.handleChange.bind(this, "original_type")}>
                        {original_type.map(original_type => (
                          <option value={original_type.id} key={original_type.id}>
                            {original_type.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["original_type"]}</span>
                </Col>
                <Col md="3">
                      <Label>Original Date</Label>
                      <Form.Control value={this.state.fields["original_date"]} required ref="original_date" type="date" name="original_date" onChange={this.handleChange.bind(this, "original_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["original_date"]}</span>
                </Col>
                <Col md="3">
                      <Label>Original Time</Label>
                      <Form.Control value={this.state.fields["original_time"]} required ref="original_time" type="time" name="original_time" onChange={this.handleChange.bind(this, "original_time")}  />
                      <span style={{color: "red"}}>{this.state.errors["original_time"]}</span>
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddTimeLogs;