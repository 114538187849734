import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../Utils/Common';

class AddLeaveTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      label: '',
      company:[],
      monitored:'',
      system:'',
      active:'',
      fields: {},
      errors: {}
  };

    this.toggle = this.toggle.bind(this);
    this.handleChangeSystem = this.handleChangeSystem.bind(this);
    this.handleChangeActive = this.handleChangeActive.bind(this);
    this.handleChangeMonitored = this.handleChangeMonitored.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){         
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeMonitored(event) {
    if(event.target.checked == true){
      this.setState({monitored: '1'});
    }
    else{
      this.setState({monitored: '0'});
    }
  }

  handleChangeSystem(event) {
    if(event.target.checked == true){
      this.setState({system: '1'});
    }
    else{
      this.setState({system: '0'});
    }
  }

  handleChangeActive(event) {
    if(event.target.checked == true){
      this.setState({active: '1'});
    }
    else{
      this.setState({active: '0'});
    }
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["label"]){
       formIsValid = false;
       errors["label"] = "Cannot be empty";
    }

    if(typeof fields["label"] !== "undefined"){
       if(!fields["label"].match(/^[a-zA-Z]+$/)){
          formIsValid = false;
          errors["label"] = "Only letters";
       }        
    }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const leave_type = {
        field_leavetype_name: this.state.fields["label"],
        field_leavetype_company: company_id,
        field_leavetype_monitored: this.state.monitored,
        field_leavetype_system: this.state.system,
        field_leavetype_active: this.state.active
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_leave_types/leave_types/`, leave_type, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/leave_types_company/leave_type_form', postData, axiosConfig)
    .then((response) => {
         self.setState({ company_id: company_id_login});
         self.setState({ company: response.data.field_leavetype_company});
         
        console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

        const { 
           company,
           company_id,
        } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Leave Type</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Leave Type</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="leave_types">Basic Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Label</Label>
                      <Form.Control value={this.state.fields["label"]} required ref="label" type="text" name="label" placeholder="Label" onChange={this.handleChange.bind(this, "label")}  />
                      <span style={{color: "red"}}>{this.state.errors["label"]}</span>
                  </Col>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
              <Col md="2">
                      <Label>Monitored</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lt-monitored"
                        label=""
                        onChange={this.handleChangeMonitored}
                        name="default"
                      />
                  </Col>
                  <Col md="2">
                      <Label>System</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lt-system"
                        label=""
                        onChange={this.handleChangeSystem}
                        name="active"
                      />
                  </Col>
                  <Col md="2">
                      <Label>Active</Label>
                      <Form.Check 
                        type="switch"
                        id="switch-lt-active"
                        label=""
                        onChange={this.handleChangeActive}
                        name="active"
                      />
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddLeaveTypes;