import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../../../Utils/Common';


class AddShiftAssignments extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      modal: false,
      name: '',
      company:[],
      shift_set:[],
      shift_employees:[],
      shift_shift_groups:[],
      shift_employee_types:[],
      shift_sites:[],
      shift_departments:[],
      fields: {},
      errors: {},
      employee:{},
      shift_group:{},
      employee_type:{},
      sites:{},
  };

  this.toggle = this.toggle.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.getSelectValuesFromEmployee = this.getSelectValuesFromEmployee.bind(this);
  this.getSelectValuesFromShiftGroup = this.getSelectValuesFromShiftGroup.bind(this);
  this.getSelectValuesFromEmployeeType = this.getSelectValuesFromEmployeeType.bind(this);
  this.getSelectValuesFromSites = this.getSelectValuesFromSites.bind(this);
  this.getSelectValuesFromDepartments = this.getSelectValuesFromDepartments.bind(this);
  this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleCloseModal () {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange(field, e){    
    let fields = this.state.fields;
    fields[field] = e.target.value;        
    this.setState({fields});
   //this.setState({ [e.target.name]: e.target.value });
  }

  getSelectValuesFromEmployee(event) {
    let employee = { employee: this.state.employee };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    employee = opts;
    this.setState({employee});
  }

  getSelectValuesFromShiftGroup(event) {
    let shift_group = { shift_group: this.state.shift_group };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    shift_group = opts;
    this.setState({shift_group});
  }


  getSelectValuesFromEmployeeType(event) {
    let employee_type = { employee_type: this.state.employee_type };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    employee_type = opts;
    this.setState({employee_type});
  }

  getSelectValuesFromSites(event) {
    let sites = { sites: this.state.sites };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    sites = opts;
    this.setState({sites});
  }
  
  getSelectValuesFromDepartments(event) {
    let department = { department: this.state.department };
    let opts = [], opt;
    
    for (let i = 0, len = event.target.options.length; i < len; i++) {
        opt = event.target.options[i];

        if (opt.selected) {
            opts.push(opt.value);
        }
    }
    department = opts;
    this.setState({department});
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!fields["name"]){
       formIsValid = false;
       errors["name"] = "Cannot be empty";
    }

    if(typeof fields["name"] !== "undefined"){
      if(!fields["name"].match(/^[a-zA-Z]+$/)){
         formIsValid = false;
         errors["name"] = "Only letters";
      }        
    }

    //Entry Date
    if(!fields["entry_date"]){
      formIsValid = false;
      errors["entry_date"] = "Cannot be empty";
    }

    //Start Date
    if(!fields["start_date"]){
      formIsValid = false;
      errors["start_date"] = "Cannot be empty";
    }
  
    //End Date
    if(!fields["end_date"]){
      formIsValid = false;
      errors["end_date"] = "Cannot be empty";
    }

    //Shift Set
    if(!fields["shift_set"]){
      formIsValid = false;
      errors["shift_set"] = "Cannot be empty";
    }

    //Employee
    // if(!fields["employee"]){
    //   formIsValid = false;
    //   errors["employee"] = "Cannot be empty";
    // }

    // //Employee
    // if(!fields["shift_group"]){
    //   formIsValid = false;
    //   errors["shift_group"] = "Cannot be empty";
    // }

    // //Employee Type
    // if(!fields["employee_type"]){
    //   formIsValid = false;
    //   errors["employee_type"] = "Cannot be empty";
    // }

    // //Site
    // if(!fields["sites"]){
    //   formIsValid = false;
    //   errors["sites"] = "Cannot be empty";
    // }

    // //Department
    // if(!fields["department"]){
    //   formIsValid = false;
    //   errors["department"] = "Cannot be empty";
    // }

   this.setState({errors: errors});
   return formIsValid;
  }

  handleSubmit(event) {

    event.preventDefault();
    
    if(this.handleValidation()){
      
      alert("Form submitted");
      this.handleCloseModal();
      const session_Name = getSessionName();
      if (!session_Name) {
        return;
      }
    
      const session_ID = getSessionID();
      if (!session_ID) {
        return;
      }
    
      const company_id = getCompanyID();
      if (!company_id) {
        return;
      }
  
      const login_token = getToken();
      if (!login_token) {
        return;
      }
  
      const shift_assignment = {
        field_assignment_label: this.state.fields["name"],
        field_assignment_entry_date:this.state.fields["entry_date"],
        field_assignment_start_date: this.state.fields["start_date"],
        field_assignment_end_date: this.state.fields["end_date"],
        field_assignment_company: company_id,
        field_assignment_shift_set: this.state.fields["shift_set"],
        field_assignment_employee: this.state.employee,
        field_assignment_group: this.state.shift_group,
        field_assignment_employee_type: this.state.employee_type,
        field_assignment_site: this.state.sites,
        field_assignment_department: this.state.department,
        field_assignment_remarks: this.state.fields["remarks"],
      };
  
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': login_token,
            'Authentication': session_Name+"="+session_ID,
        }
      };
  
      let self = this;
  
      axios.post(`http://payroll.nikkolim.com/post_shift_assignments/shift_assignments/`, shift_assignment, axiosConfig)
        .then(res => {
          console.log(res);
          console.log(res.data);
         // console.log(JSON.stringify(successMsg));
        })
        event.preventDefault();
   }else{
      alert("Form has errors.");
   }
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/shift_assignments_company/shift_assignments_form', postData, axiosConfig)
    .then((response) => {
         self.setState({ company_id: company_id_login});
         self.setState({ company: response.data.field_assignment_company});
         self.setState({ shift_set: response.data.field_assignment_shift_set});
         self.setState({ shift_employees: response.data.field_assignment_employee});
         self.setState({ shift_shift_groups: response.data.field_assignment_group});
         self.setState({ shift_employee_types: response.data.field_assignment_employee_type});
         self.setState({ shift_sites: response.data.field_assignment_site});
         self.setState({ shift_departments: response.data.field_assignment_department});
         //console.log(JSON.stringify(response.data.field_assignment_company));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

        const { 
          company_id,
          company,
          shift_set,
          shift_employees,
          shift_shift_groups,
          shift_employee_types,
          shift_sites,
          shift_departments,
        } = this.state;

    return (

        <div>
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Shift Assignments</Button>
        <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Shift Assignments</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
            <Label for="employee_information">Basic Information</Label>
              <Form.Row>
                  <Col md="6">
                      <Label>Label</Label>
                      <Form.Control value={this.state.fields["name"]} required ref="name" type="text" name="name" onChange={this.handleChange.bind(this, "name")}  />
                      <span style={{color: "red"}}>{this.state.errors["name"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Entry Date</Label>
                      <Form.Control value={this.state.fields["entry_date"]} required ref="entry_date" type="date" name="entry_date" onChange={this.handleChange.bind(this, "entry_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["entry_date"]}</span>
                  </Col>
                  <Col>
                      <Label>From</Label>
                      <Form.Control value={this.state.fields["start_date"]} required ref="start_date" type="date" name="start_date" onChange={this.handleChange.bind(this, "start_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["start_date"]}</span>
                  </Col>
                  <Col>
                      <Label>To</Label>
                      <Form.Control value={this.state.fields["end_date"]} required ref="end_date" type="date" name="end_date" onChange={this.handleChange.bind(this, "end_date")}  />
                      <span style={{color: "red"}}>{this.state.errors["end_date"]}</span>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Shift Set</Label>
                      <Form.Control as="select" value={this.state.fields["shift_set"]} required ref="shift_set" name="shift_set" onChange={this.handleChange.bind(this, "shift_set")}>
                          <option value='-'>-</option>
                          {shift_set.map(ss => (
                          <option value={ss.id} key={ss.id}>
                            {ss.name}
                          </option>
                        ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["shift_set"]}</span>
                  </Col>
                  <Col md="3">
                      <Label>Status</Label>
                      <Form.Check 
                          type="switch"
                          id="switch-shift-set-status"
                          label=""
                        />
                  </Col>
              </Form.Row>
          </fieldset>
          <fieldset name="primaryInfo">
            <Label for="employee_information">Assignment Information</Label>
              <Form.Row>
                  <Col>
                      <Label>Employees</Label>
                      <Form.Control as="select" multiple value={this.state.fields["employee"]} required ref="employee" name="employee" onChange={this.getSelectValuesFromEmployee}>
                        {shift_employees.map(se => (
                          <option value={se.id} key={se.id}>
                            {se.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["employee"]}</span>
                  </Col>
                  <Col>
                      <Label>Shift Groups</Label>
                      <Form.Control as="select" multiple value={this.state.fields["shift_group"]} required ref="shift_group" name="shift_group" onChange={this.getSelectValuesFromShiftGroup}>
                        {shift_shift_groups.map(ssg => (
                          <option value={ssg.id} key={ssg.id}>
                            {ssg.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["shift_group"]}</span>
                  </Col>
                  <Col>
                      <Label>Employee Types</Label>
                      <Form.Control as="select" multiple value={this.state.fields["employee_type"]} required ref="employee_type" name="employee_type" onChange={this.getSelectValuesFromEmployeeType}>
                        {shift_employee_types.map(set => (
                          <option value={set.id} key={set.id}>
                            {set.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["employee_type"]}</span>
                  </Col>
                  <Col>
                  <Label>Sites</Label>
                      <Form.Control as="select" multiple value={this.state.fields["sites"]} required ref="sites" name="sites" onChange={this.getSelectValuesFromSites}>
                        {shift_sites.map(ss => (
                          <option value={ss.id} key={ss.id}>
                            {ss.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["sites"]}</span>
                  </Col>
                  <Col>
                      <Label>Departments</Label>
                      <Form.Control as="select" multiple value={this.state.fields["department"]} required ref="department" name="department" onChange={this.getSelectValuesFromDepartments}>
                        {shift_departments.map(sd => (
                          <option value={sd.id} key={sd.id}>
                            {sd.name}
                          </option>
                          ))}
                      </Form.Control>
                      <span style={{color: "red"}}>{this.state.errors["department"]}</span>
                  </Col>
              </Form.Row>
          </fieldset>
          <fieldset name="primaryInfo">
            <Label for="employee_information">Assignment Information</Label>
            <Form.Row>
                  <Col>
                      <Label>Remarks</Label>
                      <Form.Control value={this.state.fields["remarks"]} required ref="remarks" as="textarea" rows="3" name="remarks" onChange={this.handleChange.bind(this, "remarks")}  />
                  </Col>
            </Form.Row>
          </fieldset>
          </Form>
          </ModalBody>
          <ModalFooter>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" type="submit">Create</Button>
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddShiftAssignments;