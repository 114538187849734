import React from 'react'
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { Form, Col, FormControl, fieldset } from 'react-bootstrap';
import { getSessionName, getSessionID, getCompanyID, getToken } from '../../../Utils/Common';

class AddEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
        modal: false,
        name: '',
        team :'' ,
        country: '',
        company_id: '',
        gender_field: [],
        marital_status: [],
        blood_type:[],
        role:[],
        type:[],
        company:[],
        department:[],
        shift_group:[],
        site:[],
        position:[],
        sss_type:[],
        pay_type:[],
        day_off_1:[],
        day_off_2:[],
        tax_deduction:[],
        retired:[],
        active:[],
    };
    this.toggle = this.toggle.bind(this);
	  this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeTeam = this.handleChangeTeam.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeTeam(event) {
    this.setState({team: event.target.value});
  }
  handleChangeGender(event) {
    this.setState({gender: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  componentDidMount () {
    let self = this;

    const session_Name = getSessionName();
    if (!session_Name) {
      return;
    }
  
    const session_ID = getSessionID();
    if (!session_ID) {
      return;
    }
  
    const company_id = getCompanyID();
    if (!company_id) {
      return;
    }

    const login_token = getToken();
    if (!login_token) {
      return;
    }
    
    var postData = {
      'company_id': company_id
    };

    var company_id_login = {
      'company_id': company_id
    }

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': login_token,
          'Authentication': session_Name+"="+session_ID,
      }
    };

    axios.post('http://payroll.nikkolim.com/get_form/employees_company/employee_form', postData, axiosConfig)
    .then((response) => {
        self.setState({ company_id: company_id_login});
        self.setState({ gender_field: response.data.field_emp_gender});
        self.setState({ marital_status: response.data.field_emp_maritalstatus});
        self.setState({ blood_type: response.data.field_emp_bloodtype});
        self.setState({ role: response.data.field_emp_role});
        self.setState({ type: response.data.field_emp_type});
        self.setState({ company: response.data.field_emp_company});
        self.setState({ department: response.data.field_emp_department});
        self.setState({ shift_group: response.data.field_emp_shift_group});
        self.setState({ site: response.data.field_emp_site});
        self.setState({ position: response.data.field_emp_position});
        self.setState({ sss_type: response.data.field_emp_ssstype});
        self.setState({ pay_type: response.data.field_emp_pay_type});
        self.setState({ day_off_1: response.data.field_emp_day_off_1});
        self.setState({ day_off_2: response.data.field_emp_day_off_2});
        self.setState({ tax_deduction: response.data.field_emp_taxdeduction});
        self.setState({ retired: response.data.field_emp_retired});
        self.setState({ active: response.data.field_emp_active});
        // console.log(JSON.stringify(response.data));
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }

  searchSubmit = e => {
    const { value } = e.target;
  };

  render() {

            const { 
                company_id_login,
                gender_field,
                marital_status,
                blood_type,
                role,
                type,
                company,
                department,
                shift_group,
                site,
                position,
                sss_type,
                pay_type,
                day_off_1,
                day_off_2,
                tax_deduction,
                retired,
                active,
            } = this.state;

    return (

        <div>
          
        <Button size="sm" color="primary" onClick={this.toggle} className="btn btn-primary" >Create Employee</Button>
        <Modal className='employeeaddform'
               size="lg"
               aria-labelledby="contained-modal-title-vcenter"
               isOpen={this.state.modal}>
        <form onSubmit={this.handleSubmit}>
          <ModalHeader>Add Employee</ModalHeader>
          <ModalBody>
          <Form>
          <fieldset name="primaryInfo">
          <legend>Personal Information</legend>
            {/* <Label for="employee_information">Personal Information</Label> */}
              <Form.Row>
                  <Col>
                      <Label>First Name</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Middle Name</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Last Name</Label>
                      <Form.Control required type="text" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Birthday</Label>
                      <Form.Control required type="date" />
                  </Col>
                  <Col>
                      <Label>Blood Type</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {blood_type.map(bloodtype => (
                          <option value={bloodtype.id} key={bloodtype.id}>
                            {bloodtype.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Hair Color</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Marital Status</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {marital_status.map(mstatus => (
                          <option value={mstatus.id} key={mstatus.id}>
                            {mstatus.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Eye Color</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Gender</Label>
                      <Form.Control defaultValue={[-1]} as="select" onChange={this.searchSubmit}>
                        {gender_field.map(gender => (
                          <option value={gender.id} key={gender.id}>
                            {gender.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Complexion</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Height</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Weight</Label>
                      <Form.Control required type="text" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Address</Label>
                      <Form.Control as="textarea" rows="2" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Email Address</Label>
                      <Form.Control required type="email" />
                  </Col>
                  <Col>
                      <Label>Telephone No.</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Mobile No.</Label>
                      <Form.Control required type="text" />
                  </Col>
              </Form.Row>
            </fieldset>
            <fieldset name="primaryInfo">
              <legend>Company Information</legend>
              <Form.Row>
                  <Col md='4'>
                      <Label>Company</Label>
                      <Form.Control disabled defaultValue={company_id_login} as="select" onChange={this.searchSubmit}>
                        {company.map(company => (
                          <option value={company.id} key={company.id}>
                            {company.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md='2'>
                      <Label>Employee ID</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                      <Label>Position</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {position.map(position => (
                          <option value={position.id} key={position.id}>
                            {position.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                    <Label>Role</Label>
                    <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {role.map(role => (
                          <option value={role.id} key={role.id}>
                            {role.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                      <Label>Department</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {department.map(department => (
                          <option value={department.id} key={department.id}>
                            {department.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Site</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {site.map(site => (
                          <option value={site.id} key={site.id}>
                            {site.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                      <Label>Shift Group</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        {shift_group.map(shiftgroup => (
                          <option value={shiftgroup.id} key={shiftgroup.id}>
                            {shiftgroup.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                  <Label>Type</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {type.map(type => (
                          <option value={type.id} key={type.id}>
                            {type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                  <Label>Hired Date</Label>
                      <Form.Control required type="date" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                  <Label>WH Status</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                  <Label>SSS Type</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {sss_type.map(sss_type => (
                          <option value={sss_type.id} key={sss_type.id}>
                            {sss_type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                  <Label>Pay Type</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {pay_type.map(pay_type => (
                          <option value={pay_type.id} key={pay_type.id}>
                            {pay_type.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                  <Label>Salary Type</Label>
                      <Form.Control required type="text" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                  <Label>SSS No.</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                  <Label>PHIC No.</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                  <Label>HDMF No.</Label>
                      <Form.Control required type="text" />
                  </Col>
                  <Col>
                  <Label>TIN.</Label>
                      <Form.Control required type="text" />
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col>
                  <Label>Day Off 1</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {day_off_1.map(day_off_1 => (
                          <option value={day_off_1.id} key={day_off_1.id}>
                            {day_off_1.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                  <Label>Day Off 2</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {day_off_2.map(day_off_2 => (
                          <option value={day_off_2.id} key={day_off_2.id}>
                            {day_off_2.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col>
                  <Label>Deduct Taxes?</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {tax_deduction.map(tax_deduction => (
                          <option value={tax_deduction.id} key={tax_deduction.id}>
                            {tax_deduction.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
              <Form.Row>
                  <Col md="3">
                  <Label>Retired</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {retired.map(retired => (
                          <option value={retired.id} key={retired.id}>
                            {retired.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
                  <Col md="3">
                  <Label>Active</Label>
                      <Form.Control defaultValue='-' as="select" onChange={this.searchSubmit}>
                        <option value='-'>-</option>
                        {active.map(active => (
                          <option value={active.id} key={active.id}>
                            {active.name}
                          </option>
                          ))}
                      </Form.Control>
                  </Col>
              </Form.Row>
            </fieldset>
            </Form>
          </ModalBody>
          <ModalFooter>
		  	      <Button className= "btn btn-secondary" onClick={this.toggle}>Cancel</Button>
              <Button className= "btn btn-secondary" onClick={this.toggle}>Submit</Button>
            {/* <input type="submit" value="Logout" color="primary" className="btn btn-primary" /> */}
          </ModalFooter>
          </form>
        </Modal>
        </div>
      
    );
  }
}

export default AddEmployee;