import React, { Component } from 'react'
import $ from 'jquery';
import '../Template/sb-admin-2.css';
import '../Template/sb-admin-2.min.css';
import { getUserRolePayrollAdmin } from '../Utils/Common';

class Menu extends Component {
  componentDidMount() {
    $("#sidebarToggle, #sidebarToggleTop").on('click', function() {
			$("body").toggleClass("sidebar-toggled");
			$(".sidebar").toggleClass("toggled");
			if ($(".sidebar").hasClass("toggled")) {
				$('.sidebar .collapse').collapse('hide');
			}
		});
		$( "#content-wrapper > div > .container-fluid").click(function() {
			if($('body').hasClass('sidebar-toggled')){
				$('.sidebar .nav-link').attr('aria-expanded',false);
				$('.sidebar .collapse').removeClass('show');
				$('.sidebar .nav-link').addClass('collapsed');				
			}
			if ($(window).width() < 768) {
				$('.sidebar .nav-link').attr('aria-expanded',false);
				$('.sidebar .collapse').removeClass('show');
				$('.sidebar .nav-link').addClass('collapsed');
				if(!$('#accordionSidebar').hasClass('toggled')){
					$("body").removeClass("sidebar-toggled");
					$('#accordionSidebar').addClass('toggled')
				}
			}
		});
		$(window).resize(function() {
			if ($(window).width() < 768) {
				$('.sidebar .collapse').collapse('hide');
			}
    });
  }
  render() {
    const perrole = ()=>{
      if(getUserRolePayrollAdmin() === 'Payroll Administrator'){
        return (
            <div>
            <li className="nav-item active">
              <a className="nav-link" href="/dashboard">
              <i class="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/company-info">
              <i className="fa fa-building"></i>
              <span>Company Info</span></a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/employees">
              <i className="fa fa-users"></i>
              <span>Employees</span></a>
            </li>
            {/* 
            <li className="nav-item active">
              <a className="nav-link" href="/">
              <i className="fa fa-money"></i>
              <span>Salary Management</span></a>
            </li> */}
            
            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#classification" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-money"></i>
                <span>Classifications</span>
              </a>
              <div id="classification" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="/employee-types">Employee Types</a>
                  {/* <a className="collapse-item" href="/classification">Classification</a> */}
                  <a className="collapse-item" href="/classification-choice">Classification Choice</a>
                  <a className="collapse-item" href="/classification-type">Classification Type</a>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#salarymanagement" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-money"></i>
                <span>Salary Management</span>
              </a>
              <div id="salarymanagement" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  {/* <a className="collapse-item" href="buttons.html">Salary Informations</a> */}
                  <a className="collapse-item" href="/pay-schedule">Pay Schedule</a>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#internaltransactions" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-ellipsis-v"></i>
                <span>Internal Transactions</span>
              </a>
              <div id="internaltransactions" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="/leaves">Leaves</a>
                  <a className="collapse-item" href="/loans">Loans</a>
                  <a className="collapse-item" href="/adjustments">Adjustments</a>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#timemanagement" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-clock-o"></i>
                <span>Time Management</span>
              </a>
              <div id="timemanagement" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="/time-records">Time Records</a>
                  <a className="collapse-item" href="/time-logs">Time Logs</a>
                  <a className="collapse-item" href="buttons.html">Time Summaries</a>
                  <a className="collapse-item" href="/time-authorizations">Time Authorizations</a>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#configurations" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-cog"></i>
                <span>Configurations</span>
              </a>1
              <div id="configurations" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="/shift-maintenance">Shift Maintenance</a>
                  <a className="collapse-item" href="/holidays">Holidays</a>
                  <a className="collapse-item" href="buttons.html">Tables</a>
                  <a className="collapse-item" href="/devices">Devices</a>
                  <a className="collapse-item" href="/loan-types">Loan Types</a>
                  <a className="collapse-item" href="/leave-types">Leave Types</a>
                  <a className="collapse-item" href="/adjustment-types">Adjustment Types</a>
                  <a className="collapse-item" href="/salary-rate-keys">Salary Rate Keys</a>
                  <a className="collapse-item" href="/pay-types">Pay Types</a>
                  <a className="collapse-item" href="/pay-frequency">Pay Frequency</a>
                </div>
              </div>
            </li>
          </div>
        )
      }
      if(getUserRolePayrollAdmin() === 'Conglomerate User'){
        return (
            <div>
            <li className="nav-item active">
              <a className="nav-link" href="/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></a>
            </li>
          </div>
        )
      }
      if(getUserRolePayrollAdmin() === 'Employee'){
        return (
            <div>
            <li className="nav-item active">
              <a className="nav-link" href="/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span></a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/overview">
              <i className="fa fa-user-circle"></i>
              <span>Overview</span></a>
            </li>

            <li className="nav-item">
              <a className="nav-link collapsed" href="/" data-toggle="collapse" data-target="#mytransactions" aria-expanded="true" aria-controls="collapseTwo">
                <i className="fa fa-clock-o"></i>
                <span>My Transactions</span>
              </a>
              <div id="mytransactions" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="/employee-leaves">Leaves</a>
                  <a className="collapse-item" href="/employee-loans">Loans</a>
                  <a className="collapse-item" href="/employee-adjustments">Adjustments</a>
                  <a className="collapse-item" href="/employee-time-authorizations">Time Authorizations</a>
                </div>
              </div>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/employee-time-records">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Time Records</span></a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/employee-time-logs">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Time Logs</span></a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Pay Records</span></a>
            </li>
          </div>
        )
      }
    }

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
          <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/dashboard">
            <div className="sidebar-brand-icon rotate-n-15">
              <i className="fas fa-laugh-wink"></i>
            </div>
            <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
          </a>
          {perrole()}
          <div className="text-center d-none d-md-inline">
            <button className="rounded-circle border-0" id="sidebarToggle"></button>
          </div>
        </ul>
    )
  }
}


export default Menu;