

  // return the user data from the session storage
  export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }

  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token');
  }

  // return the User Image from the session storage
  export const getUserImage = () => {
    return localStorage.getItem('user_image');
  }

  // return the user role from the session storage
  export const getUserRolePayrollAdmin = () => {
    return localStorage.getItem('user_role');
  }

  //return the session ID from session storage
  export const getSessionID = () => {
    return localStorage.getItem('session_ID');
  }

  //return the User ID from session storage
  export const getUserID = () => {
    return localStorage.getItem('user_id');
  }

  //return the session Name from session storage
  export const getSessionName = () => {
    return localStorage.getItem('session_Name');
  }

  //return the Company ID from session storage
  export const getCompanyID = () => {
    return localStorage.getItem('company_ID');
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.clear();
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, full_name, sessid, session_name, company_id, user_role, user_image, user_id) => {
    localStorage.setItem('token', token);
    localStorage.setItem('session_ID', sessid);
    localStorage.setItem('session_Name', session_name);
    localStorage.setItem('company_ID', company_id);
    localStorage.setItem('user_role', user_role);
    localStorage.setItem('user_image', user_image);
    localStorage.setItem('user_id', user_id);
    localStorage.setItem('user', JSON.stringify(full_name));
  }